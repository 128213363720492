import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import get from "lodash/get";
import { ArrowDown, ArrowUp, File } from "react-feather";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import MedicineModel from "../../wasfaty/Models/MedicineSelectionForMadicineAvailbilityModel";
import SelfAssessmentServices from "../../wasfaty/services/SelfAssessmentServices";
import "./style.scss";
import moment from "moment";
import { IntlService, TaskService } from "../../wasfaty/services";
import ComplianceInformationShow from "./ComplianceInformationShow";
import { SelfAssessmentModal } from "../../wasfaty/Models/SelfAssessmentModal";
import SubmissionReview from "../../components/SubmissionReview";
function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url?.toLowerCase?.());
}

function SelfAssessmentDetails({ itemProp }) {
  const [item, setItem] = useState(itemProp);
  const [isLoading, setLoading] = useState(false);
  const { id, task_id } = useParams();

  useEffect(() => {
    if (id && !itemProp) {
      SelfAssessmentServices.show(id).then((res) => {
        setItem(new SelfAssessmentModal(res.data?.data));
        setLoading(true);
      });
    }
  }, [id]);

  useEffect(async () => {
    if (!itemProp && task_id) {
      let task = await TaskService.show(task_id);

      if (task?.data) {
        setItem(new SelfAssessmentModal(null, task.data.data));
      }
    }
  }, [task_id, itemProp]);

  if (!item) return null;

  const TextView = ({ attribute, title }) => {
    return (
      <Col md="4" sm="12">
        <p>
          <FormattedMessage id={title} defaultMessage={title} />
        </p>
        <h4>{item[attribute]}</h4>
      </Col>
    );
  };

  const medicineModel = new MedicineModel();
  const isIdentification = item?.formFields?.["identification"];

  return (
    <>
      {!itemProp && task_id && <SubmissionReview task={item.task} />}

      <Row id="AuditDetails">
        <Col>
          {!item && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                width: "100%",
              }}
            >
              <Spinner
                color="primary"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </div>
          )}

          <nav
            className="navbar navbar-expand-md mb-1 py-1"
            style={{ backgroundColor: "#F6F8FA", color: "white" }}
          >
            <div className="container-fluid">
              <Link
                className="navbar-brand"
                to="#"
                style={{ color: "white", fontWeight: "500" }}
              >
                {IntlService.m(itemProp ? "Review " : " Details")}
              </Link>
            </div>
          </nav>
          {item && (
            <>
              {isIdentification && (
                <>
                  <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
                    <CardBody>
                      <Row>
                        <Col md="4" sm="12">
                          <p>
                            <FormattedMessage
                              id="Region"
                              defaultMessage={"Region"}
                            />
                          </p>
                          <h4>{item?.region?.name}</h4>
                        </Col>
                        <Col md="4" sm="12">
                          <p>
                            <FormattedMessage
                              id="Site"
                              defaultMessage={"Site"}
                            />
                          </p>
                          <h4>{item.site?.name || item.site?.name_ar}</h4>
                        </Col>
                        <Col md="4" sm="12">
                          <p>
                            <FormattedMessage
                              id="City"
                              defaultMessage={"City"}
                            />
                          </p>
                          <h4>{item.city || "N/A"}</h4>
                        </Col>
                        <Col md="4" sm="12">
                          <p>
                            <FormattedMessage
                              id="Street name"
                              defaultMessage={"Street name"}
                            />
                          </p>
                          <h4>{item.street_name || "N/A"}</h4>
                        </Col>
                        <Col md="4" sm="12">
                          <p>
                            <FormattedMessage
                              id="District Name"
                              defaultMessage={"District Name"}
                            />
                          </p>
                          <h4>{item.district_name || "N/A"}</h4>
                        </Col>
                      </Row>
                      <Row>
                        {/* <TextView
                      item={item}
                      attribute={"owner_name"}
                      title={"The name of the owner of the company"}
                    />
                    <TextView
                      item={item}
                      attribute={"name_of_the_institution"}
                      title={"The full name of the institution"}
                    />
                    <TextView
                      item={item}
                      attribute={"institution_email"}
                      title={"Institution email"}
                    /> */}

                        {/* <TextView
                      item={item}
                      attribute={"cbahi_certified"}
                      title={"Is the facility CBAHI certified?"}
                    />
                    {item.cbahi_certified == "Yes" && (
                      <TextView
                      
                        item={item}
                        attribute={"facility_license_number"}
                        title={"Facility license number"}
                      />
                    )} */}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
                    <CardBody>
                      <Row>
                        <Col md="4" sm="12">
                          <h2>
                            <FormattedMessage
                              id="Location"
                              defaultMessage={"Location"}
                            />
                          </h2>
                        </Col>
                        <Col md="4" sm="12">
                          <div className="_vaccine">
                            <p>
                              <FormattedMessage
                                id="Longitude"
                                defaultMessage={"Longitude"}
                              />
                            </p>
                            <h3>{item.longitude}</h3>
                          </div>
                        </Col>
                        <Col md="4" sm="12">
                          <div className="_vaccine">
                            <p>
                              <FormattedMessage
                                id="Latitude"
                                defaultMessage={"Latitude"}
                              />
                            </p>
                            <h3>{item.latitude}</h3>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}

              {!!item.medicine.length &&
                item?.formFields?.["general_medicine"] && (
                  <Card
                    style={{
                      borderRadius: "0px",
                      marginBottom: "5px",
                    }}
                  >
                    <h2 className="p-1">
                      <FormattedMessage
                        defaultMessage="General Medicine"
                        id="General Medicine"
                      />
                    </h2>
                    <CardBody>
                      {item.medicine.map((med, index) => (
                        <Row
                          style={{
                            borderBottom: "1.2px dashed #D1D1D1",
                            padding: "10px 0px",
                          }}
                        >
                          <div className="d-flex flex-row">
                            <h4>#{index + 1}</h4>
                            {!!med.quantity_available && (
                              <>
                                {/* {(
                                Math.abs(
                                  (med.medicine?.medicine_quantity -
                                    med.quantity_available) /
                                    ((med.medicine?.medicine_quantity +
                                      med.quantity_available) /
                                      2)
                                ) * 100
                              ).toFixed(2)} % */}
                                <h5>
                                  &nbsp;&nbsp;&nbsp;
                                  <FormattedMessage
                                    id={`Min Quantity`}
                                    defaultMessage={`Min Quantity`}
                                  />
                                  {` (${
                                    med.medicine?.medicine_quantity ||
                                    IntlService.m("N/A")
                                  })`}
                                  {med.medicine?.medicine_quantity >
                                  med.quantity_available ? (
                                    <ArrowDown
                                      size={"sm"}
                                      color={"red"}
                                      width={20}
                                    />
                                  ) : (
                                    <ArrowUp
                                      size={"sm"}
                                      color={"green"}
                                      width={20}
                                    />
                                  )}
                                </h5>
                              </>
                            )}
                          </div>
                          {medicineModel.exportObj.map((ite) => {
                            let optionExixst = get(med, ite.attribute);
                            let moreOpFile = null;
                            moreOpFile = get(med, `${ite.attribute}_file`)
                              ?.length
                              ? get(med, `${ite.attribute}_file`)
                              : get(med, `${ite.attribute}_file`)?.images;
                            if (optionExixst)
                              return (
                                <>
                                  <Col md="4" sm="12">
                                    <div className="_vaccine">
                                      {ite.title != "Reporting Date" && (
                                        <>
                                          <p>
                                            <FormattedMessage
                                              id={ite.title}
                                              defaultMessage={ite.title}
                                            />
                                          </p>
                                          <h3>{optionExixst}</h3>
                                        </>
                                      )}{" "}
                                      {med.complian_provide_this_medicine ==
                                        "Yes" &&
                                      ite.title == "Reporting Date" ? (
                                        <>
                                          <p>
                                            <FormattedMessage
                                              id="Reporting Date"
                                              defaultMessage="Reporting Date"
                                            />
                                          </p>
                                          <h3>
                                            {moment(optionExixst).format(
                                              "YYYY/M/D"
                                            )}
                                          </h3>
                                        </>
                                      ) : null}
                                      {!!ite.moreOp && (
                                        <>
                                          {get(
                                            med,
                                            `${ite.attribute}_other`
                                          ) && (
                                            <>
                                              <p>
                                                <FormattedMessage
                                                  id={"Comments"}
                                                  defaultMessage={"Comments"}
                                                />
                                              </p>
                                              <h3>
                                                {get(
                                                  med,
                                                  `${ite.attribute}_other`
                                                )}
                                              </h3>
                                            </>
                                          )}
                                          {!!moreOpFile &&
                                            !!moreOpFile?.length && (
                                              <>
                                                <p>
                                                  <FormattedMessage
                                                    id={"Files"}
                                                    defaultMessage={"Files"}
                                                  />
                                                </p>
                                                <p>
                                                  {moreOpFile.map(
                                                    (uImage, key) => {
                                                      return isImage(uImage) ? (
                                                        <div className="_vaccine mb-25 mt-25">
                                                          <img
                                                            src={uImage}
                                                            style={{
                                                              width: "50%",
                                                              height: "50%",
                                                              marginRight: 50,
                                                            }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="_vaccine">
                                                          <a
                                                            href={uImage}
                                                            className="mb-25 mt-25"
                                                          >
                                                            <File />
                                                            {uImage
                                                              .substr(
                                                                uImage.length -
                                                                  9
                                                              )
                                                              .replaceAll(
                                                                "%",
                                                                ""
                                                              )}
                                                          </a>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </p>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </>
                              );
                          })}
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                )}
              {!!item.medicineRes.length &&
                item?.formFields?.["respiratory_medicine"] && (
                  <Card
                    style={{
                      borderRadius: "0px",
                      marginBottom: "5px",
                    }}
                  >
                    <h2 className="p-1">
                      <FormattedMessage
                        defaultMessage="Respiratory Medicine"
                        id="Respiratory Medicine"
                      />
                    </h2>
                    <CardBody>
                      {item.medicineRes.map((med, index) => (
                        <Row
                          style={{
                            borderBottom: "1.2px dashed #D1D1D1",
                            padding: "10px 0px",
                          }}
                        >
                          <div className="d-flex flex-row">
                            <h4>#{index + 1}</h4>
                            {!!med.quantity_available && (
                              <>
                                {/* {(
                                Math.abs(
                                  (med.medicine?.medicine_quantity -
                                    med.quantity_available) /
                                    ((med.medicine?.medicine_quantity +
                                      med.quantity_available) /
                                      2)
                                ) * 100
                              ).toFixed(2)} % */}
                                <h5>
                                  &nbsp;&nbsp;&nbsp;
                                  <FormattedMessage
                                    id={`Min Quantity`}
                                    defaultMessage={`Min Quantity`}
                                  />
                                  {` (${
                                    med.medicine?.medicine_quantity ||
                                    IntlService.m("N/A")
                                  })`}
                                  {med.medicine?.medicine_quantity >
                                  med.quantity_available ? (
                                    <ArrowDown
                                      size={"sm"}
                                      color={"red"}
                                      width={20}
                                    />
                                  ) : (
                                    <ArrowUp
                                      size={"sm"}
                                      color={"green"}
                                      width={20}
                                    />
                                  )}
                                </h5>
                              </>
                            )}
                          </div>
                          {medicineModel.exportObj.map((ite) => {
                            let optionExixst = get(med, ite.attribute);
                            let moreOpFile = null;
                            moreOpFile = get(med, `${ite.attribute}_file`)
                              ?.length
                              ? get(med, `${ite.attribute}_file`)
                              : get(med, `${ite.attribute}_file`)?.images;
                            if (optionExixst)
                              return (
                                <>
                                  <Col md="4" sm="12">
                                    <div className="_vaccine">
                                      {ite.title != "Reporting Date" && (
                                        <>
                                          <p>
                                            <FormattedMessage
                                              id={ite.title}
                                              defaultMessage={ite.title}
                                            />
                                          </p>
                                          <h3>{optionExixst}</h3>
                                        </>
                                      )}{" "}
                                      {med.complian_provide_this_medicine ==
                                        "Yes" &&
                                      ite.title == "Reporting Date" ? (
                                        <>
                                          <p>
                                            <FormattedMessage
                                              id="Reporting Date"
                                              defaultMessage="Reporting Date"
                                            />
                                          </p>
                                          <h3>
                                            {moment(optionExixst).format(
                                              "YYYY/M/D"
                                            )}
                                          </h3>
                                        </>
                                      ) : null}
                                      {!!ite.moreOp && (
                                        <>
                                          {get(
                                            med,
                                            `${ite.attribute}_other`
                                          ) && (
                                            <>
                                              <p>
                                                <FormattedMessage
                                                  id={"Comments"}
                                                  defaultMessage={"Comments"}
                                                />
                                              </p>
                                              <h3>
                                                {get(
                                                  med,
                                                  `${ite.attribute}_other`
                                                )}
                                              </h3>
                                            </>
                                          )}
                                          {!!moreOpFile &&
                                            !!moreOpFile?.length && (
                                              <>
                                                <p>
                                                  <FormattedMessage
                                                    id={"Files"}
                                                    defaultMessage={"Files"}
                                                  />
                                                </p>
                                                <p>
                                                  {moreOpFile.map(
                                                    (uImage, key) => {
                                                      return isImage(uImage) ? (
                                                        <div className="_vaccine mb-25 mt-25">
                                                          <img
                                                            src={uImage}
                                                            style={{
                                                              width: "50%",
                                                              height: "50%",
                                                              marginRight: 50,
                                                            }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="_vaccine">
                                                          <a
                                                            href={uImage}
                                                            className="mb-25 mt-25"
                                                          >
                                                            <File />
                                                            {uImage
                                                              .substr(
                                                                uImage.length -
                                                                  9
                                                              )
                                                              .replaceAll(
                                                                "%",
                                                                ""
                                                              )}
                                                          </a>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </p>
                                              </>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </>
                              );
                          })}
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                )}
            </>
          )}
        </Col>

        {!!item?.formFields?.["additionalRemarks"] && (
          <ComplianceInformationShow item={item} />
        )}
      </Row>
    </>
  );
}

export default SelfAssessmentDetails;
