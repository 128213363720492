import React, { createRef } from "react";
import {
  makeAutoObservable,
  autorun,
  toJS,
  reaction,
  observe,
  runInAction,
} from "mobx";
import { discardAutoSave } from "../Helper/utils";
import { DraftService, EventEmitter } from "../services";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import moment from "moment";
import { getUserData } from "@utils";

import {
  makePersistable,
  getPersistedStore,
  stopPersisting,
  clearPersistedStore,
} from "mobx-persist-store";
import localForage from "localforage";
import AuthUser from "../services/AuthService";

const handlers = {};
let locationListner = null;

class DraftModal {
  slug = "";
  draft = "";
  isLoading = false;
  isDrafting = null;

  isSaving = false;
  autoSaveTime = 1; // min
  lastSaveTime = moment();
  saveCountDown = 9;

  autoSaveHandler = null;
  countDownTimerHandler = null;

  isDraftLoaded = false;
  payload = null;
  isDiscarded = false;

  constructor(parent) {
    this.parent = parent;
    this.slug = AuthUser?.user?._id  +  this.parent.class_name;
    this.isDrafting = !parent.isEdit;

    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: getUserData()?._id + this.parent.class_name,
        properties: ["payload"],
        storage: localForage,
      },
      { delay: 100, fireImmediately: false }
    );
    this.init();
  }

  init = () => {
    if (this.isDrafting) {
      this.getDraft();
      this.autoSave();
      // this.onTabClose();
      this.onLocationChange();
    }
  };

  onLocationChange = () => {
    if (locationListner) {
      locationListner.unsubscribe();
    }

    if (EventEmitter?.subscribe) {
      locationListner = EventEmitter.subscribe((location) => {
        this.saveDraft();
      });
    }
  };

  clearDraft = async () => {
    this.removeListner();
    await this.clearStoredData();
  };

  autoSave = () => {
    this.removeListner();

    const counter = () => {
      runInAction(() => {
        this.saveCountDown = 9;
      });

      if (handlers[this.slug + "countDownTimerHandler"]) {
        clearInterval(handlers[this.slug + "countDownTimerHandler"]);
      }
      handlers[this.slug + "countDownTimerHandler"] = setInterval(() => {
        runInAction(() => {
          this.saveCountDown--;
        });
      }, 1000);
    };

    handlers[this.slug + "_autoSaveHandler"] = setInterval(() => {
      counter();

      this.saveDraft();
    }, this.autoSaveTime * 10 * 1000);

    counter();
  };

  sync = async () => {
    await this.saveDraft();
    this.autoSave();
  };

  onTabClose = () => {
    // window.addEventListener("beforeunload", (ev) => {
    //   ev.preventDefault();
    //   this.removeListner();
    //   this.saveDraft();
    //   return (ev.returnValue = "Are you sure you want to close?");
    // });
  };

  getDraft = async () => {
    runInAction(async () => {
      this.isLoading = true;
      if (this.isDrafting) {
        let { payload } = await getPersistedStore(this);
        if (payload) {
          // const draft = await DraftService.find({ slug: this.slug });
          this.draft = payload;
          if (this.draft) {
            this.parent.init(this.draft);
          }
        }
      }
    });
  };

  saveDraft = async () => {
    if (window?.document?.hidden) return;
    runInAction(async () => {
      this.isSaving = true;
      this.draft = this.parent.getPayload(true);
      this.payload = this.parent.getPayload(true);

      setTimeout(() => {
        runInAction(() => {
          this.lastSaveTime = moment();
          this.isSaving = false;
        });
      }, 1000);
    });
  };

  setLoading = (check) => {
    runInAction(() => {
      this.isLoading = check;
    });
  };

  removeListner = () => {
    Object.entries(handlers).forEach(([key, value]) => {
      if (value) {
        clearInterval(value);
        delete handlers[key];
      }
    });

    if (locationListner) {
      locationListner.unsubscribe();
    }
  };

  stopAutoSave = () => {
    this.removeListner();
  };

  discardDraft = async () => {
    let { isConfirmed } = await discardAutoSave();

    if (isConfirmed) {
      this.stopAutoSave();
      this.payload = null;
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  clearStoredData = async () => {
    await clearPersistedStore(this);
  };

  stopStore = () => {
    stopPersisting(this);
  };
}

export default DraftModal;
