import { Resources } from "../Resource";

import { SC } from "../Api/serverCall";
import toast from "react-hot-toast";
import NavigationService from "./NavigationService";
class Service extends Resources {
  route = "SelfAssessmentTasks";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  commentPostUrl = "SelfAssessmentTasks/comment/store";
  commentDeleteUrl = "SelfAssessmentTasks/comment/delete";
  commentUpdateUrl = "SelfAssessmentTasks/comment/update";

  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    return Object.keys(obj);
  }

  mapExportData(data) {
    try {
      data = data.map((item) => {
        return item;
      });
    } catch (error) {
      console.log("Inspection export error: ", error);
    }
    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }

  submitTask = async (item) => {
    if (await item.isValid()) {
      let data = item.getPayload();

      this.update(item.task._id, { data }).then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.data);
          item?.draft?.clearDraft?.();
          NavigationService.Navigation("/My-Task");
        } else {
          toast.error(res?.response?.data?.data);
        }
      });
      return;
    }
  };

  updateStatus = async (status, id, message) => {
    let postData = {
      id,
      message,
      reject_reason: message,
    };
    return SC.postCall({ url: `${this.route}/${status}`, data: postData });
  };

  sendComment = (postData) => {
    return SC.postCall({ url: this.commentPostUrl, data: postData });
  };

  deleteComment = (postData) => {
    return SC.postCall({ url: this.commentDeleteUrl, data: postData });
  };

  updateComment = (postData) => {
    return SC.postCall({ url: this.commentUpdateUrl, data: postData });
  };

  reAssign = async (task_id, user_id) => {
    let postData = {
      user_id,
      task_id,
    };
    return SC.postCall({ url: `${this.route}/reassign`, data: postData });
  };
}
const SelfAssessmentTaskService = new Service();
export default SelfAssessmentTaskService;
