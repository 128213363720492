import React from "react";
import loginLogo from "../assets/illustrations.svg";
import { IntlService } from "../../wasfaty/services";

function ContentSection() {
  return (
    <div className="content_section">
      <div style={{ height: "10rem" }}></div>
      <div className="d-flex justify-content-center" style={{ marginTop: 30 }}>
        <img src={loginLogo} className="image" />
      </div>
      <div className="text_content fotor">
        <p className="head">
          {IntlService.m("Utilizing the latest digital technologies to construct a comprehensive management system ensuring adherence to health regulations.")}
        </p>
        <p className="info">
          {IntlService.m(
            "Utilizing artificial intelligence and big data analysis for advanced quality oversight and regulatory compliance"
          )}
        </p>
      </div>
    </div>
  );
}

export default ContentSection;
