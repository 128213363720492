import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "schedule";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };

  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    return Object.keys(obj);
  }

  mapExportData(data) {
    try {
      data = data.map((item) => {
        return {
          ...item,
          tasks: item?.tasks?.length,
          user: item?.user?.name,
        };
      });
    } catch (error) {
      console.log("Inspection export error: ", error);
    }
    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }

  updateDueDate = (id, due_date) => {
    return SC.putCall({
      url: this.route + `/dueDateUpdate/${id}`,
      data: { due_date: new Date(due_date).toDateString() },
    });
  };

  getOtp = () => {
    return SC.postCall({
      url: `teleinspection/otp`,
    });
  };

  valifdateOtp = (otp) => {
    return SC.postCall({
      url: `teleinspection/otp/validation`,
      data: {
        otpkey: otp,
      },
    });
  };
}
const ScheduleTaskService = new Service();
export default ScheduleTaskService;
