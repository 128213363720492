import { makeAutoObservable } from "mobx";
import toast from "react-hot-toast";
import moment from "moment";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

import Inspector from "./InspectorModel";
import { ClusterService, IntlService } from "../services";
import { steperForm } from "./AllFormNamesAndSteps";
import ScheduleMedicineAvailabilityService from "../services/ScheduleMedicineAvailabilityService";
import AuthUser from "../services/AuthService";
export const formFields = {};

const flatten = function (form) {
  return Object.entries(form)
    .map(([key, attributes]) =>
      attributes.map((item) => {
        item.parent = key;
        return item;
      })
    )
    .flatMap((attribute) => attribute);
};

export class TaskModal {
  _id = "";
  __id = "";
  name = "";
  label = "Select";
  value = "";
  site = null;
  inspector = null;
  isOpen = true;
  isPending = true;

  constructor(tasks) {
    this.init(tasks);
    makeAutoObservable(this);
  }

  init(task) {
    if (task) {
      this._id = task._id;
      this.__id = task._id;
      this.isPending = task.is_pending;
      this.site = {
        label: task.site.name_ar,
        value: task.site._id,
        ...task.site,
      };
      this.inspector = {
        label: task.inspector.name,
        value: task.inspector._id,
        ...task.inspector,
      };
    } else {
      this.__id = uuidv4();
    }
  }
  setAttribute(name, value) {
    this[name] = value;
  }
}

export class ScheduleMedicineAvailabilityModal {
  class_name = "ScheduleMedicineAvailabilityModal";
  isValidation = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;

  // Server fields require for payload
  _id = "";
  region_id = "";
  site_id = "";
  region = null;
  cluster = null;
  supervisor = null;
  inspector = null;
  dueDate = "";
  site = null;
  start_date = "";
  end_date = "";
  user = "";
  form_name = "";
  user_id = ""; // Current Logged In user
  tasks = "";

  sites = [];
  clusters = [];
  supervisors = [];
  inspectors = [];
  totalSites = 0;
  clusterLoading = false;
  supervisorsLoading = false;
  inspectorsLoading = false;
  siteLoading = false;

  tasks = [];

  isEdit = false;
  steperForm = steperForm;
  get_all = false;
  siteAvailablityStatus = true;
  siteAvailablityMsg = "";

  mapingStartDate = "";
  mapingEndDate = "";

  constructor(props, task) {
    this.isEdit = !!props;
    if (this.isEdit) {
      this.init(props);
    } else {
      this.getAvailableDates();
    }

    makeAutoObservable(this);
  }

  init(props) {
    if (props) {
      this._id = props._id;
      if (props?.tasks.length) {
        this.tasks = props.tasks.map((task) => new TaskModal(task));
      } else {
        this.tasks.push(new TaskModal());
      }
      this.setPayload(props);
      this.cluster_id = props.cluster_id;
      this.dueDate = props.due_date;
      this.region_id = props.region_id;
      this.getAvailableSites();
      this.getInspectors();
    }
  }

  setPayload(data) {
    const { region, cluster, supervisor, due_date } = data;

    this.region = { value: region?.name, label: region?.name };
    this.cluster = { value: cluster?.name, label: cluster?.name };
    this.supervisor = { value: supervisor?.name, label: supervisor?.name };
    this.dueDate = new Date(due_date);
    this.isEdit = true;
  }

  getAvailableDates() {
    ScheduleMedicineAvailabilityService.getAvailableDates().then((result) => {
      this.mapingStartDate = result?.start_date;
      this.mapingEndDate = result?.end_date;
    });
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  clearInspectorsSteps = () => {
    this.tasks.forEach((task) => task.clearSteps());
  };

  setRegion(value) {
    this.region = value;
  }

  setCluster(value) {
    this.cluster = value;
  }

  setSite(value) {
    this.site = value;
  }
  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    this.isReview = check;
  }

  getPayload() {
    if (this.isEdit) {
      const tasksData = this.tasks.map((task) => {
        return {
          site_id: task.site._id,
          inspector_id: task.inspector._id,
          task_id: task._id,
        };
      });
      return {
        tasks: tasksData,
      };
    }

    return {
      region_id: this.region._id,
      cluster_id: this.cluster._id,
      supervisor_id: this.supervisor._id,
      due_date: this.dueDate?.toDateString?.(),
      form_name: "Pharmacies Self Assessment - Medicine Availability",
      form_id: process.env.REACT_APP_MEDICINE_AVAILIBILITY_FORM,
    };
  }

  isValid() {
    let check = false;
    if (AuthUser.isSupervisor) {
      check = this.tasks.every((task) => {
        return task.site && task.inspector;
      });
    } else {
      check = this.region && this.supervisor && this.dueDate;
    }
    return check;
  }

  isDuplicateSites() {
    // Check if there are any duplicates in the tasks array
    const siteIds = this.tasks.map((task) => task.site._id);
    const uniqueSiteIds = new Set(siteIds);

    // If the size of the set is less than the size of the original array, there are duplicates
    return uniqueSiteIds.size === siteIds.length;
  }

  review = () => {
    let check = this.isValid();
    if (!check) {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
      return;
    }
    const isDuplicateSites = this.isDuplicateSites();
    if (!isDuplicateSites) {
      this.isValidation = true;
      toast.error(IntlService.m("Dublicate sites cannot be add"));
      return;
    }

    this.setIsReview(true);
    this.isValidation = false;
    this.test = !this.test;
  };

  getClusters = () => {
    const regionId = this.region._id;
    if (regionId) {
      this.clusterLoading = true;
      this.clusters = [];
      this.cluster = null;
      ClusterService.getClustersByRegionID(regionId)
        .then((res) => {
          this.clusters = res;
          this.clusterLoading = false;
        })
        .catch(() => {
          this.clusterLoading = false;
        });
    }
  };

  getSupervisors = (search = "") => {
    const regionId = this.region?._id;
    if (regionId) {
      const params = {
        regionId,
        search,
        type: "supervisor",
      };
      this.supervisorsLoading = true;
      ScheduleMedicineAvailabilityService.getUsersByRegionID(params)
        .then((res) => {
          this.supervisors = res;
        })
        .finally(() => (this.supervisorsLoading = false));
    }
  };

  getInspectors = (search = "") => {
    const regionId = this.region_id;
    if (regionId) {
      const params = {
        regionId,
        search,
        type: "agent",
      };
      this.inspectorsLoading = true;
      ScheduleMedicineAvailabilityService.getUsersByRegionID(params)
        .then((res) => {
          this.inspectors = res;
        })
        .finally(() => (this.inspectorsLoading = false));
    }
  };

  getAvailableSites = (search = "") => {
    if (this.region_id) {
      this.siteLoading = true;
      this.sites = [];
      const params = {
        regionId: this.region_id,
        module: "Pharmacies Self Assessment - Medicine Availability",
        dueDate: this.dueDate?.toDateString?.(),
        search,
      };
      ScheduleMedicineAvailabilityService.getAvailableSites(params)
        .then((res) => {
          let _data = get(res, "data");
          if (_data?.length && res?.status) {
            const sites = _data.map((item) => ({
              ...item,
              label: item.name,
              value: item._id,
            }));
            this.sites = sites;
          }
        })
        .finally(() => {
          this.siteLoading = false;
        });
    }
  };

  addTask = () => {
    this.tasks.push(new TaskModal());
  };

  removeTask = () => {
    if (this.tasks.length <= 1) {
      toast.error(IntlService.m("At least one task must be present."));
      return;
    }

    const lastTask = this.tasks[this.tasks.length - 1];
    if (lastTask.isPending) {
      this.tasks.pop();
    }
  };

  removeSpecificTask = (id) => {
    if (this.tasks.length <= 1) {
      toast.error(IntlService.m("At least one task must be present."));
      return;
    }

    this.tasks = this.tasks.filter(
      (task) => !(task.__id === id && task.isPending)
    );
  };
}
