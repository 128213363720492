import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { language } from "../assets/svg";
import { IntlService } from "../../wasfaty/services";
import { handleRTLLocalStorage } from "../../../redux/layout";
import { useDispatch } from "react-redux";

function Footer() {
  const dispatch = useDispatch();

  const handleLanguageSwitch = (lg) => {
    dispatch(handleRTLLocalStorage(lg === "en" ? false : true));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const currentDate = new Date(); // Create a new Date object with the current date and time
  const year = currentDate.getFullYear();
  return (
    <div className="d-flex justify-content-between p-2 fotor align-items-center">
      <p>
        © {year} {IntlService.m("Al-Munazam")}
      </p>

      <UncontrolledDropdown className=" custom-dropdown" direction="up">
        <DropdownToggle color="transparent" caret>
          <span>
            {language} {IntlService.isRtl ? "إن" : "ENG"}
          </span>
        </DropdownToggle>
        <DropdownMenu className="rounded-lg">
          <DropdownItem
            className="w-100"
            onClick={() => {
              handleLanguageSwitch("en");
            }}
          >
            English
          </DropdownItem>
          <DropdownItem
            className="w-100"
            onClick={() => {
              handleLanguageSwitch("sa");
            }}
          >
            العربية
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export default Footer;
