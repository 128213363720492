import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import SignatureCanvas from "react-signature-canvas";
import { observer } from "mobx-react-lite";
import { getValidPhone } from "../../wasfaty/Helper/utils";
import { File } from "react-feather";
import TextView from "../../components/TextView";
import DateView from "../../components/DateView";
import "./style.scss";
import moment from "moment";

const Signature = observer(({ inspector }) => {
  return (
    <div className="signature">
      <SignatureCanvas
        clearOnResize={false}
        ref={(ref) => {
          ref?.off?.();
          inspector.setSignatureRef(ref);
        }}
        penColor="transparent"
        canvasProps={{
          className: "signature-canvas",
        }}
      />
    </div>
  );
});

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url?.toLowerCase?.());
}

function ComplianceInformationShow({ item, except = [] }) {
  const isInspectorsShow = !item.task;
  return (
    <>
      {item.complianceMembers.map((inspector, key) => (
        <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
          <CardBody>
            <h3>
              <FormattedMessage id={`Member`} defaultMessage={`Member`} />#{" "}
              {key + 1}
            </h3>
            <Row>
              <Col md="4" sm="12">
                <p>
                  <FormattedMessage id="Name" defaultMessage={"Name"} />
                </p>
                <h4>{inspector.name || "N/A"}</h4>
              </Col>

              <Col md="12" sm="12">
                <p>
                  <FormattedMessage
                    id="Signature"
                    defaultMessage={"Signature"}
                  />
                </p>
                <Signature inspector={inspector} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
      <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
        <CardBody>
          <Row>
            <TextView
              item={item}
              attribute={"compDelegatedPerson"}
              title={"Delegated Person"}
            />
            <TextView
              item={item}
              attribute={"compIdNumber"}
              title={"Id Number"}
            />
            <TextView
              item={item}
              attribute={"compIssueDate"}
              title={"Issue Date"}
              value={moment(new Date(item.compIssueDate)).format("YYYY-MM-DD")}
            />
            <TextView
              item={item}
              attribute={"compIssuePlace"}
              title={"Issue Place"}
            />
            <TextView
              item={item}
              attribute={"composition"}
              title={"Position"}
            />

            <Col md="12" sm="12">
              <p>
                <FormattedMessage id="Signature" defaultMessage={"Signature"} />
              </p>
              <Signature inspector={item.compSignature} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default ComplianceInformationShow;
