import { Col, Row } from 'antd';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { SiteService } from "../../../views/wasfaty/services";
import './styles/siteOptions.scss'
import ScheduleSelfAssessmentServices from '../../../views/wasfaty/services/ScheduleSelfAssessmentServices';

const SitesOptionsModel = ({item, modal, toggle }) => {
  const [groupedSites, setGroupedSites] = useState([])
  const [loading, setLoading] = useState(false)
  const toggleClose = () => toggle()

  useEffect(() => {
    if (modal) {
      if (!item?.isAllSitesSelected) {
        groupAndSortByInitial(item?.site)
      } else {
        getAllAvailableSites()
      }
    }
  }, [modal]);

  const getAllAvailableSites = (clster) => {
    const params = {
      form_name: item.form_name?.name,
      start_date: item.start_date?.toDateString?.(),
      end_date: item.end_date?.toDateString?.(),
      region_ids: item.region?.map((region) => region._id) || [],
      cluster_ids: item.cluster?.map((cluster) => cluster._id) || [],
      get_all: true
    };
    setLoading(true)
    ScheduleSelfAssessmentServices.getAvailableSites(params)
      .then((res) => {
        groupAndSortByInitial(res.data)
      }).catch((err) => {
        console.log("🚀 ~ getAllAvailableSites ~ .then ~ err:", err)
      }).finally(()=> setLoading(false))
  }

  const groupAndSortByInitial = (sites) => {
    const groupedData = sites.reduce((acc, { name_ar, name }) => {
      const initial = name_ar.charAt(0)
      acc[initial] = acc[initial] || [];
      acc[initial].push(name_ar);
      return acc;
    }, {});
  
    const grpData = Object.keys(groupedData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedData[key];
        return acc;
      }, {});
      
    setGroupedSites(grpData);
  };

  return (
    <Fragment>
      <Modal centered className="modal_main" isOpen={modal} toggle={toggleClose} unmountOnClose={true}>
        <ModalHeader toggle={toggleClose}>Sites Name</ModalHeader>
        <ModalBody>
          <div className="modal_body">
          {
            loading ? (
              <div className="text-center my-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <Fragment>
                {Object.keys(groupedSites)?.map((key) => (
                  <>
                    <div className="hr"/>
                    <div className="grouped_value">{key}</div>
                    <Row className='gap-1 names_row'>
                      {
                        groupedSites[key].map((site, i) => (
                          <Col key={i} className="col-auto">
                            <div className="names">{site}</div>
                          </Col>
                        ))
                      }
                    </Row>
                  </>
                ))}
                </Fragment>
              )
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default SitesOptionsModel
