import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  ModalHeader,
} from "reactstrap";

import "./style.scss";
import { Minus, Plus, Trash } from "react-feather";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { FormBuilderModel } from "../models/FormBuilderModel";
import { IntlService } from "../../wasfaty/services";
import { BsPencil } from "react-icons/bs";

export const appStatusProps = {
  ACTIVE: {
    color: "green",
    label: "Active",
    count: 0,
  },
  INACTIVE: {
    color: "brown",
    label: "In Active",
    count: 0,
  },
};

const TextInput = observer(
  ({ attribute, title, md = "6", value, onChange, isRequired = true }) => {
    return (
      <Col className="mb-1" md={md} sm="12">
        <Label className="form-label">
          <FormattedMessage id={title} defaultMessage={title} />{" "}
          {isRequired ? " *" : ""}
        </Label>
        <Input
          type="text"
          value={value}
          onChange={(e) => onChange(attribute, e.target.value)}
        />
      </Col>
    );
  }
);

const Templates = observer(({ templates, callback, deleteTemplate }) => {
  const [templateModal, setTemplateMddal] = useState(false);
  const [createModalStates, setCreateModalstates] = useState(
    () => new FormBuilderModel()
  );

  const [createForm, setCreateForm] = useState(false);

  return (
    <Fragment className="template_modal">
      <Modal
        unmountOnClose={true}
        isOpen={templateModal}
        toggle={() => {
          setTemplateMddal((r) => !r);
        }}
      >
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <h3>Templates </h3>
          <div
            style={{ width: 50, height: 30, cursor: "pointer" }}
            onClick={() => setCreateForm((r) => !r)}
          >
            {createForm ? <Minus size={18} /> : <Plus size={18} />}
          </div>
        </ModalHeader>
        <ModalBody className="d-flex flex-column">
          {createForm ? (
            <Row>
              <TextInput
                title="Name"
                attribute="formName"
                value={createModalStates.formName}
                md="12"
                onChange={createModalStates.handleChange}
                isValid={createModalStates.createValidation}
              />

              <Button.Ripple
                onClick={() => {
                  if (createModalStates.formName) {
                    createModalStates.submitTemplate(() => {
                      setTimeout(() => {
                        setCreateForm(false);
                      }, 1000);
                      callback();
                    });
                  }
                }}
                style={{ width: 80 }}
                className="add-form-button waves-effect round btn btn-primary my-1"
              >
                <p>{IntlService.m("Submit")}</p>
              </Button.Ripple>
            </Row>
          ) : (
            <div>
              <div>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <h4>{IntlService.m("Template Name")}</h4>
                  <h4>{IntlService.m("Actions")}</h4>
                </div>
              </div>
              {templates.map((form, index) => {
                return (
                  <div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <p>
                        {index + 1} - {form.name}
                      </p>
                      <div>
                        <a
                          onClick={() => deleteTemplate(form._id)}
                          style={{ marginRight: 10 }}
                        >
                          <Trash size={16} color="red" />
                        </a>
                        <a
                          onClick={() => createModalStates.openEngine(form._id)}
                        >
                          <BsPencil size={16} />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </ModalBody>
        <div></div>
      </Modal>

      <Button.Ripple
        onClick={() => {
          setTemplateMddal(true);
        }}
        className="add-form-button waves-effect round btn btn-primary my-1"
      >
        <p className="">
          <FormattedMessage defaultMessage={"Templates"} id={"Templates"} />
        </p>
        <Plus size={18} />
      </Button.Ripple>
    </Fragment>
  );
});

export default Templates;
