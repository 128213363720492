import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";

class ACLService extends Resources {
  route = "RolePermission"; 
  routes = {
    find: "/GetAll",
    create: "/createRole",
    show: "/GetRoleById",
    update: "/UpdateRole",
    delete: "/DeleteRole",
    export: "acl/export",
  };

  isDotNet = true;

  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    if (obj) {
      return [
        { title: "Role Name", dataIndex: "name" },
        { title: "Permissions", dataIndex: "permissions" },
        { title: "Created At", dataIndex: "created_at" },
      ];
    }

    return [];
  }

  // Fetch all roles
  getAllRoles = async () => {
    try {
      let res = await SC.getCall({ url: this.routes.find });
      let _data = get(res, "data.data");
      return _data || [];
    } catch (e) {
      console.error("Error fetching roles:", e);
      return [];
    }
  };

  // Create a new role
  createRole = async (roleData) => {
    try {
      const res = await SC.postCall({ url: this.routes.create, data: roleData });
      return get(res, "data");
    } catch (e) {
      console.error("Error creating role:", e);
      return null;
    }
  };

  // Update an existing role
  updateRole = async (roleId, roleData) => {
    try {
      const res = await SC.putCall({ url: `${this.routes.update}/${roleId}`, data: roleData });
      return get(res, "data");
    } catch (e) {
      console.error("Error updating role:", e);
      return null;
    }
  };

  // Delete a role
  deleteRole = async (roleId) => {
    try {
      const res = await SC.deleteCall({ url: `${this.routes.delete}/${roleId}` });
      return get(res, "data");
    } catch (e) {
      console.error("Error deleting role:", e);
      return null;
    }
  };

  // Map export data for roles
  mapExportData(data = []) {
    try {
      data = data.map((item) => {
        item.name = get(item, "name", "");
        item.permissions = get(item, "permissions", []).join(", ");
        item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");
        return item;
      });
    } catch (error) {
      console.log("ACL export error:", error);
    }

    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }
}

export default Singleton(ACLService);
