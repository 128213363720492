import { makeAutoObservable } from "mobx";
import React from "react";
import { v4 as uuidv4 } from "uuid";

class ComplianceMember {
  name = "";

  signature = "";
  signatureImg = "";
  isOpen = true;

  onlysignatureInstance = false;

  steps = [];
  signatureRef = React.createRef();
  constructor(payload, onlysignature) {
    this.__id = uuidv4();

    if (onlysignature) {
      this.onlysignatureInstance = onlysignature;
    }

    if (payload) {
      this.name = payload?.name;
      this.signature = payload.signature;
    }

    makeAutoObservable(this);
  }

  setValues = () => {
    if (this.signatureRef?.current) {
      this.signature = this.signatureRef.current.toData();
      this.signatureImg = this.signatureRef.current.toDataURL();
    }
  };

  setSignatureRef(ref) {
    this.signatureRef.current = ref;
    if (this.signature && ref?.fromData) {
      ref.fromData(this.signature);
      setTimeout(() => {
        this.signatureImg = ref.toDataURL();
      }, 1000);
    }
  }

  isValid() {
    let check = this.name && this.signature?.length;

    return check;
  }

  getPayload() {
    let payload = {};
    payload.name = this.name;

    payload.signature = this.signature;

    return payload;
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  clearCanvas() {
    if (this.signatureRef?.current) {
      this.signatureRef?.current.clear();
      this.signature = "";
    }
  }

  updateUser(user) {
    this.name = user?.name;
    this.user = user;
  }

  clearSteps = () => {
    this.steps = [];
  };
}

export default ComplianceMember;
