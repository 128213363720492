import { makeAutoObservable, autorun, toJS, reaction, observe } from "mobx";
import React, { createRef } from "react";
import { phoneValidator, emailValidator, getValidPhone } from "../Helper/utils";
import { v4 as uuidv4 } from "uuid";

class Staff {
  department = "";
  department_name = "";
  staff_number = "";
  minimum_staff = ""; // Is the minimum staff amount available
  minimum_staff_other = "";
  isOpen = true;

  pdfObject = [
    {
      title: "Department",
      attribute: "department",
    },
    {
      title: "Staff Number",
      attribute: "staff_number",
    },
    {
      title: "Is the minimum staff amount available?",
      attribute: "minimum_staff",
    },
    {
      title: "Reason",
      attribute: "minimum_staff_other",
    },
  ];

  constructor(staff) {
    this.__id = uuidv4();
    if (staff) {
      if (staff.department) {
        this.department = { value: staff.department, label: staff.department };
      }
      this.staff_number = staff.staff_number;
      this.minimum_staff = staff.minimum_staff;
      this.minimum_staff_other = staff.minimum_staff_other;
      this.department_name = staff.department_name;
    }
    makeAutoObservable(this);
  }

  isValid() {
    let check = this.staff_number && this.minimum_staff;

    check = check && (this.department_name || this.department);

    return check;
  }

  getPayload() {
    let payload = {};
    payload.department = this.department?.value;
    payload.staff_number = this.staff_number;
    payload.minimum_staff = this.minimum_staff;

    if (this.isMinimumStaffOther) {
      payload.minimum_staff_other = this.minimum_staff_other;
    }
    payload.department_name = this.department_name;

    return payload;
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  get isMinimumStaffOther() {
    return this.minimum_staff === "No";
  }
}

export default Staff;
