import React, { useEffect, useState } from "react";
import ACLService from "../../../wasfaty/services/ACLService";
import './RoleManagement.css'; // Import the CSS file

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState({
    _id: "", // Add _id field for new roles
    name: "",
    health_directorate: [],
    responsible_department: [],
    permission_ids: []
  });
  const [editingRoleId, setEditingRoleId] = useState(null);
  const [editingRole, setEditingRole] = useState({
    _id: "", // Add _id field for editing roles
    name: "",
    health_directorate: [],
    responsible_department: [],
    permission_ids: []
  });

  const fetchRoles = async () => {
    try {
      const allRoles = await ACLService.find();
      console.log("API Response:", allRoles.data.data.data); // Log the raw response
      var allRoles_ = allRoles.data.data.data;
      if (Array.isArray(allRoles_)) {
        console.log("Fetched roles:", allRoles_);
        setRoles(allRoles_);
      } else {
        console.error("Expected an array but received:", allRoles_);
        setRoles([]);
      }
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };
  
  useEffect(() => {
    fetchRoles(); // Call fetchRoles when the component mounts
  }, []);

  const handleCreateRole = async (e) => {
    e.preventDefault();
    if (!newRole.name) return;

    try {
      console.log("Creating role:", newRole); // Log role being created
      await ACLService.create(newRole);
      setNewRole({
        _id: "", // Reset _id
        name: "",
        health_directorate: [],
        responsible_department: [],
        permission_ids: []
      }); // Clear input
      fetchRoles(); // Refresh roles
    } catch (error) {
      console.error("Failed to create role:", error);
    }
  };

  const handleUpdateRole = async (e) => {
    e.preventDefault();
    if (!editingRole.name) return;

    try {
      console.log("Updating role with ID:", editingRoleId); // Log ID of role being updated
      console.log("Role data:", editingRole); // Log role data
      await ACLService.update(editingRoleId, editingRole);
      setEditingRoleId(null); // Reset editing state
      setEditingRole({
        _id: "", // Reset _id
        name: "",
        health_directorate: [],
        responsible_department: [],
        permission_ids: []
      }); // Clear input
      fetchRoles(); // Refresh roles
    } catch (error) {
      console.error("Failed to update role:", error);
    }
  };

  const handleDeleteRole = async (roleId) => {
    console.log("Deleting role with ID:", roleId); // Log ID of role being deleted
    try {
      await ACLService.delete(roleId);
      fetchRoles(); // Refresh roles
    } catch (error) {
      console.error("Failed to delete role:", error);
    }
  };

  return (
    <div className="role-management">
      <h1>Role Management</h1>
      
      <form onSubmit={handleCreateRole}>
        <input
          type="text"
          placeholder="Role Name"
          value={newRole.name}
          onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Health Directorate"
          value={newRole.health_directorate.join(', ')}
          onChange={(e) => setNewRole({ ...newRole, health_directorate: e.target.value.split(', ') })}
        />
        <input
          type="text"
          placeholder="Responsible Department"
          value={newRole.responsible_department.join(', ')}
          onChange={(e) => setNewRole({ ...newRole, responsible_department: e.target.value.split(', ') })}
        />
        <input
          type="text"
          placeholder="Permission IDs"
          value={newRole.permission_ids.join(', ')}
          onChange={(e) => setNewRole({ ...newRole, permission_ids: e.target.value.split(', ') })}
        />
        <input type="hidden" value={newRole._id} /> {/* Hidden input for _id */}
        <button type="submit">Create Role</button>
      </form>

      {editingRoleId && (
        <form onSubmit={handleUpdateRole}>
          <input
            type="text"
            placeholder="Edit Role Name"
            value={editingRole.name}
            onChange={(e) => setEditingRole({ ...editingRole, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="Edit Health Directorate"
            value={editingRole.health_directorate.join(', ')}
            onChange={(e) => setEditingRole({ ...editingRole, health_directorate: e.target.value.split(', ') })}
          />
          <input
            type="text"
            placeholder="Edit Responsible Department"
            value={editingRole.responsible_department.join(', ')}
            onChange={(e) => setEditingRole({ ...editingRole, responsible_department: e.target.value.split(', ') })}
          />
          <input
            type="text"
            placeholder="Edit Permission IDs"
            value={editingRole.permission_ids.join(', ')}
            onChange={(e) => setEditingRole({ ...editingRole, permission_ids: e.target.value.split(', ') })}
          />
          <input type="hidden" value={editingRole._id} /> {/* Hidden input for _id */}
          <button type="submit">Update Role</button>
        </form>
      )}

      <ul>
        {roles.length > 0 ? (
          roles.map((role) => (
            <li key={role._id}> {/* Use _id for the key */}
              {role.name}
              <div>Health Directorate: {role.health_directorate.join(', ')}</div>
              <div>Responsible Department: {role.responsible_department.join(', ')}</div>
              <div>Permission IDs: {role.permission_ids.join(', ')}</div>
              <button onClick={() => {
                setEditingRoleId(role._id); // Use _id for editing
                setEditingRole({
                  _id: role._id, // Set _id for editing
                  name: role.name,
                  health_directorate: role.health_directorate,
                  responsible_department: role.responsible_department,
                  permission_ids: role.permission_ids
                });
              }}>
                Edit
              </button>
              <button onClick={() => handleDeleteRole(role._id)}>Delete</button> {/* Use _id for deletion */}
            </li>
          ))
        ) : (
          <li>No roles available</li> // Message when no roles are available
        )}
      </ul>
    </div>
  );
};

export default RoleManagement;
