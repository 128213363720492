import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Progress } from "reactstrap";
// import { ScheduleTaskModal } from "../../wasfaty/Models/ScheduleTaskModal";
import { ScheduleTaskService } from "../../wasfaty/services";
import {
  eyeSvg,
  timeSvg,
  timeSvgbig,
} from "../List/components/scheduleTask/SVGSheduleAsset";
import "./style.scss";

import "../List/components/scheduleTask/style.scss";

import startCase from "lodash/startCase";

function ScheduleDetails({}) {
  const [schedule, setSchedule] = useState();
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  console.log("schedule", schedule);
  useEffect(() => {
    if (id) {
      setLoading(true);
      ScheduleTaskService.show(id).then((res) => {
        if (res.status == 200) {
          setSchedule(res.data.data);
        }
        setLoading(false);
      });
    }
  }, [id]);

  if (!schedule) return null;

  let percentage =
    (schedule.tasks.filter((task) => task.is_approved).length /
      schedule.tasks.length) *
    100;
  return (
    <>
      <Card>
        <CardBody className="schedule">
          <div className="head">
            <p className="title">{startCase(schedule.form_name)}</p>

            <div className="bar">
              <div className="d-flex justify-content-between align-items-center">
                <p className="label">{schedule.user.name}</p>
                <p className="percent">{percentage} %</p>
              </div>
              <Progress
                value={percentage}
                color={schedule.is_completed ? "success" : "danger"}
                className="progress"
                style={{
                  backgroundColor: "#F5ABAB",
                  height: "8px",
                }}
              />
            </div>
            {/* <div className="devider-line"></div> */}

            <div className="time-info">
              <figure>{timeSvgbig}</figure>
              <p className="m-0">
                {moment(schedule.created_at).format("llll")}{" "}
              </p>
            </div>

            <div
              className={`status-badge-${
                schedule.is_completed ? "completed" : "notcompleted"
              }`}
            >
              <p>{schedule.is_completed ? "Completed" : "Not Completed"}</p>
            </div>
          </div>
          <div className="content">
            <div>
              <p className="label">Task Details</p>
              <p className="user-name">Awais</p>
            </div>{" "}
            <div>
              <p className="label">Assigned Steps</p>
              <p className="label">Assigned Steps</p>
            </div>{" "}
            <div>
              <p className="label">Due Date</p>
              <p className="date">Nov 10th, 2023 11:30 AM</p>
            </div>{" "}
            <div>
              <p className="label">Status</p>
              <div
                className={`status-badge-${
                  schedule.is_completed ? "completed" : "notcompleted"
                }`}
              >
                <p>{schedule.is_completed ? "Completed" : "Not Completed"}</p>
              </div>
            </div>
            <div>
              <Button className="buton">View Details</Button>
            </div>
          </div>
          <div className="d-flex justify-content-end butonContainer">
            <Button
              className="detail-button"
              // onClick={() =>
              //   NavigationService.Navigation(
              //     `/Schedule-Task/Details/${schedule._id}`
              //   )
              // }
            >
              <span>{eyeSvg}</span> View Details
            </Button>
          </div>
        </CardBody>{" "}
      </Card>
    </>
  );
}
export default ScheduleDetails;
