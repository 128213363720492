import { Fragment, useState } from "react";
import { Button, Row } from "reactstrap";
import Dropdown from "../../wasfaty/Helper/DropdownB";
import {
  IntlService,
  NavigationService,
  ScheduleMedicalErrorTaskService,
} from "../../wasfaty/services";
import ExportExcel from "../../components/ExportExcel";
import "./styles.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ListHeader from "../../../@core/components/ListHeader/ListHeader";
import ScheduleCardView from "./components/scheduleTask/ScheduleCardView";
import { ScheduleMedicalErrorTaskModal } from "../../wasfaty/Models/ScheduleMedicalErrorTaskModal";
import PaginationFooter from "../../wasfaty/components/listing/PaginationFooter";
import AuthUser from "../../wasfaty/services/AuthService";
import { List } from "react-feather";
const MySwal = withReactContent(Swal);

function ScheduleMedical() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalPages: 0,
    pageCount: 0,
    to: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const [loading, setLoading] = useState(false);

  const getAllItems = (query) => {
    setIsLoading(true);
    setLoading(true);

    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      //filters
      schedule_type: "medical_meeting_error",
      ...filter,
    };

    ScheduleMedicalErrorTaskService.find(params)
      .then((res) => {
        if (res?.data?.data) {
          setItems(
            res.data.data.data.map(
              (shedule) => new ScheduleMedicalErrorTaskModal(shedule)
            )
          );
          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perPage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perPage);

          const to = _pagination.to;

          setPagination({
            page,
            pageCount,
            perPage,
            totalPages,
            to,
          });
        }
        setLoading(false);
        setIsLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setIsLoading(false);
      });
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        ScheduleMedicalErrorTaskService.delete(id).then((res) => {
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };
  return (
    <Fragment>
      <ListHeader
        pagination={pagination}
        exportComponent={
          null
          // <ExportExcel
          //   apiCall={true}
          //   endPoint={`${ScheduleMedicalErrorTaskService.route}`}
          //   fileName={"ScheduleTask"}
          //   exportFilter={{ per_page: pagination.totalPages, page: 0 }}
          //   handleExportContent={({ data }) => {
          //     return ScheduleMedicalErrorTaskService.mapExportData(data.data || []);
          //   }}
          // />
        }
        addFormUrl={
          AuthUser.isAdmin ||
          AuthUser.isSupervisor ||
          AuthUser.isRegionalRepresentatives
            ? "/Schedule-Medical-Error/Add"
            : false
        }
        dashboardUrl="/Schedule-Medical-Error/Schedule-Calander"
        dashboardText="Calender"
        filter={filter}
        setFilter={setFilter}
        getData={getAllItems}
        loading={loading}
        addButtonText="Add Schedule"
        thirdButton={
          <div className=" mx-2 cursor-pointer " style={{ marginTop: 17 }}>
            <List
              size={36}
              onClick={() =>
                NavigationService.Navigation(
                  "/66618f81734c1ccf32036a72/Form-Builder"
                )
              }
            />
          </div>
        }
      />
      <Row>
        <Row className="d-flex align-items-center justify-content-center">
          {!items?.length && !isLoading && (
            <h5 className="w-auto">No Schedule Available!</h5>
          )}
        </Row>
        <ScheduleCardView schedules={items} DeleteData={DeleteData} />
        <PaginationFooter
          pagination={pagination}
          handlePageChange={getAllItems}
        />
      </Row>
    </Fragment>
  );
}

export default ScheduleMedical;
