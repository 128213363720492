import { makeAutoObservable, autorun, observable } from "mobx";
export class FacilityModel {
  options = [
    "Hospital",
    "Private Hospital",
    "Pharmacy",
    "Pharmaceutical facility",
    "Medical complex",
    "Ambulance Transport Service Centers",
    "Clinic",
    "Day Surgery Center",
    "Health Support Service Centers",
    "Radiology Center",
    "PHC Primary Healthcare",
    "Rehabilitation Center",
    "NVR Center",
    "Kidney Center",
    "Lab",
    "Specialized Center"
  ];

  constructor(props) {}
}
