import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Spinner,
} from "reactstrap";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import debounce from "lodash/debounce";
import { ChevronDown, ChevronUp } from "react-feather";

import { selectThemeColors } from "../../../../utility/Utils";
import {
  CalendarIcon,
  ChevronLeftGreen,
  ChevronLeftWhite,
  ChevronRightWhite,
  DeleteIconRed,
} from "../../../../assets/wasfaty/SVG";
import {
  IntlService,
  RegionService,
  ScheduleMedicineAvailabilityService,
} from "../../../wasfaty/services";
import { ScheduleMedicineAvailabilityModal } from "../../../wasfaty/Models/ScheduleMedicineAvailabilityModal";
import ScheduleMedicineAvailabilityDetails from "../../Show/ScheduleMedicineAvailability";
import AuthUser from "../../../wasfaty/services/AuthService";
import JournalModal from "../../../../@core/components/journalModal";

import "@styles/react/libs/flatpickr/flatpickr.scss";
import "./style.scss";

const ScheduleMedicineAvailability = observer(() => {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [allDay, setAllDay] = useState(false);
  const [regions, setRegions] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [medicines, setMedicines] = useState([]);

  const intl = useIntl();
  const { id } = useParams();

  useEffect(() => {
    init();
  }, [id]);

  useEffect(() => {
    if (item?.dueDate) {
      getData();
    }
  }, [item?.dueDate]);

  const init = async () => {
    let isEdit = !!id;

    if (!isEdit) {
      // Find all regions to present in the dropdown menu
      RegionService.getByMethod("Pharmacy").then(setRegions);

      let _item = new ScheduleMedicineAvailabilityModal();
      setItem(_item);
      // Set the current login user id
      // _item.setUserId(getUserData());
    } else {
      let res = await ScheduleMedicineAvailabilityService.show(id);
      let _item = new ScheduleMedicineAvailabilityModal(res.data.data);
      _item.isEdit = true;
      setItem(_item);
    }
  };

  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  const ValidationHandler = observer(
    ({ isValid, message = `"This field is required!"` }) => {
      return (
        <>
          <Input type="text" hidden invalid={!isValid && item.isValidation} />
          <FormFeedback>
            <FormattedMessage id={message} defaultMessage={message} />
          </FormFeedback>
        </>
      );
    }
  );

  const submit = async (e) => {
    e.preventDefault();
    let isValid = await item.isValid();

    if (isValid) {
      item.setIsSubmit(true);
      let payload = item.getPayload();
      let promise = null;
      if (item.isEdit) {
        promise = ScheduleMedicineAvailabilityService.update(id, payload);
      } else {
        promise = ScheduleMedicineAvailabilityService.create(payload);
      }
      promise
        .then(async (res) => {
          if (res.status === 200 && res?.data?.status) {
            toast.success(res.data?.message, {
              duration: 4000,
            });
            navigate("/Schedule-Medicine-Availability");
          } else {
            item.setIsSubmit(false);
            toast.error(res.data?.message);
          }
        })
        .catch((error) => {
          let e = error.response?.data?.errors;
          if (e && typeof e === "object") {
            Object.entries(e).forEach(([key, value]) => toast.error(value));
          } else {
            toast.error("Something went wrong!");
          }

          item.setIsSubmit(false);
        });

      return;
    }
    item.setValidation(true);
  };

  const Submit = observer(() => {
    return (
      <div className="d-flex justify-content-end">
        <Button.Ripple
          className="round border-none "
          color="primary"
          type="submit"
          onClick={submit}
          disabled={item.isSubmit}
        >
          {item.isSubmit && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span style={{ color: "white" }}>
            {item.isEdit ? (
              <FormattedMessage id="Update" defaultMessage="Update" />
            ) : (
              <FormattedMessage id="Submit" defaultMessage="Submit" />
            )}
          </span>
        </Button.Ripple>
      </div>
    );
  });

  const filterOptions = (items, id, name, apiCallingFun) => {
    const filteredOptions = items.filter((obj) => obj._id !== id);
    item.setAttribute([name], filteredOptions);
    if (!!apiCallingFun) item[apiCallingFun]();
  };

  const toggleModal = () => setModal(!modal);

  const dueDateOptions = () => {
    if (id) {
      return {};
    } else {
      return {
        dateFormat: "d/m/y",
        minDate: moment().add(1, "days").startOf("day").toDate(), // Start from tomorrow
        enable: [
          function (date) {
            const today = moment().startOf("day"); // Get today's date without time
            const mappingStart = moment(
              item.mapingStartDate,
              "YYYY-MM-DD"
            ).startOf("day");
            const mappingEnd = moment(item.mapingEndDate, "YYYY-MM-DD").startOf(
              "day"
            );

            // If today is greater than mapingEndDate
            if (today.isAfter(mappingEnd, "day")) {
              const nextMonth = moment().add(1, "month").startOf("month"); // First day of the next month
              const nextMonthStart = nextMonth
                .clone()
                .date(mappingStart.date());
              const nextMonthEnd = nextMonth.clone().date(mappingEnd.date());
              return (
                moment(date).isSameOrAfter(nextMonthStart, "day") &&
                moment(date).isSameOrBefore(nextMonthEnd, "day")
              );
            } else {
              // If today is before or equal to mapingEndDate, enable mapingStartDate to mapingEndDate of the current month
              return (
                moment(date).isSameOrAfter(mappingStart, "day") &&
                moment(date).isSameOrBefore(mappingEnd, "day")
              );
            }
          },
        ],
      };
    }
  };

  const getData = () => {
    const params = {
      due_date: item?.dueDate?.toDateString?.(),
    };
    setModalLoading(true);
    ScheduleMedicineAvailabilityService.getMedicines(params)
      .then((res) => {
        setMedicines(res);
      })
      .finally(() => setModalLoading(false));
  };

  const filteredSiteOptions = () => {
    const siteIds = [];
    item.tasks.map((task) => {
      if (task?.site?._id) siteIds.push(task.site?._id);
    });
    return item.sites
      .filter((obj) => !siteIds.includes(obj._id))
      .map((item) => {
        return {
          ...item,
          label: getTranslation(item.name_ar || item.name),
        };
      });
  };

  if (!item) return null;
  return (
    <Fragment>
      {item.isReview ? (
        <ScheduleMedicineAvailabilityDetails
          item={item}
          modalData={medicines}
        />
      ) : (
        <Fragment>
          <JournalModal
            title={"Medicines"}
            data={medicines}
            modal={modal}
            modalLoading={modalLoading}
            toggle={toggleModal}
          />
          <Card className="p-2 schedule_self_ass">
            <Row className="">
              <Col className="" md="6" sm="12">
                <Label className="form_label">
                  <FormattedMessage id="Regions" defaultMessage={"Regions"} />
                </Label>
                <Select
                  name="region"
                  value={item.region}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={intl.formatMessage({
                    id: "Add regions...",
                    defaultMessage: "Add regions...",
                  })}
                  onChange={(e) => {
                    item.setAttribute("region", e);
                    item.getClusters();
                    item.getSupervisors();
                  }}
                  options={regions.map((item) => {
                    return {
                      ...item,
                      label: getTranslation(item?.name),
                    };
                  })}
                  isDisabled={AuthUser.isSupervisor}
                />
                <ValidationHandler isValid={item.region} />
              </Col>{" "}
              <Col className="" md="6" sm="12">
                <Label className="form_label">
                  <FormattedMessage
                    id="cluster_name"
                    defaultMessage={"Cluster Name"}
                  />
                </Label>
                <Select
                  name="cluster"
                  value={item.cluster}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  isLoading={item.clusterLoading}
                  placeholder={intl.formatMessage({
                    id: item.clusterLoading ? "Loading..." : "Select",
                    defaultMessage: item.clusterLoading
                      ? "Loading..."
                      : "Select",
                  })}
                  onChange={(e) => {
                    item.setAttribute("cluster", e);
                  }}
                  options={item?.clusters?.map((item) => {
                    return {
                      ...item,
                      label: getTranslation(item.name),
                    };
                  })}
                  isDisabled={AuthUser.isSupervisor || !item.region}
                />
              </Col>{" "}
              <Col className="dates" md="6" sm="12">
                <Label className="form_label">
                  <FormattedMessage
                    id="supervisor_name"
                    defaultMessage={"Supervisor Name"}
                  />
                </Label>
                <Select
                  name="supervisor"
                  value={item.supervisor}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  isLoading={item.supervisorsLoading}
                  placeholder={intl.formatMessage({
                    id: item.supervisorsLoading ? "Loading..." : "Select",
                    defaultMessage: item.supervisorsLoading
                      ? "Loading..."
                      : "Select",
                  })}
                  onChange={(e) => {
                    item.setAttribute("supervisor", e);
                  }}
                  onInputChange={debounce((inputValue, { action }) => {
                    if (action === "input-change") {
                      item.getSupervisors(inputValue);
                    }
                  }, 1000)}
                  options={item.supervisors?.map((item) => {
                    return {
                      ...item,
                      label: getTranslation(item.name_ar || item.name),
                    };
                  })}
                  isDisabled={AuthUser.isSupervisor || !item.region}
                />
                <ValidationHandler isValid={item?.supervisor} />
              </Col>{" "}
              <Col className="dates " md="6" sm="12">
                <Label className="form_label" for="dueDate">
                  Due Date
                </Label>
                <Flatpickr
                  style={{
                    backgroundColor: AuthUser.isSupervisor
                      ? "hsl(0, 0%, 95%)"
                      : "",
                  }}
                  required
                  placeholder="DD / MM /YYYY"
                  id="dueDate"
                  name="dueDate"
                  className="form-control"
                  onChange={(date) => item.setAttribute("dueDate", date[0])}
                  value={item.dueDate}
                  options={dueDateOptions()}
                  disabled={AuthUser.isSupervisor}
                />
                <span className="calander_icon">{CalendarIcon}</span>
                <ValidationHandler isValid={item.dueDate} />
              </Col>{" "}
            </Row>
            {item.dueDate && medicines.length > 0 && (
              <Row>
                <Col className="all_medicines mt-2">
                  <span onClick={toggleModal}>Medicines</span>
                </Col>
              </Row>
            )}
          </Card>
          {AuthUser.isSupervisor && (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center m-1">
                <Label
                  for="icon-success"
                  className="form-check-label text-black"
                >
                  {IntlService.m("Assign tasks to inspectors")}
                </Label>
                <div className="form-switch form-check-success d-flex mainRepeater">
                  <p
                    className="steper-plus-minus"
                    onClick={() => item.addTask("inspector")}
                  >
                    +
                  </p>

                  <div className="add_form">
                    <p className="stepper-text-style">{item.tasks.length}</p>
                  </div>
                  <p
                    className="steper-plus-minus"
                    onClick={() => item.removeTask()}
                  >
                    -
                  </p>
                </div>
              </div>
              {item?.tasks?.map((task, key) => {
                return (
                  <Card key={task.__id}>
                    <CardBody>
                      <Row>
                        <Col
                          md="4"
                          className="d-flex flex-row align-items-center p-1"
                        >
                          {task.isOpen ? (
                            <ChevronDown
                              className="chevron"
                              onClick={() => task.setAttribute("isOpen", false)}
                            />
                          ) : (
                            <ChevronUp
                              className="chevron"
                              onClick={() => task.setAttribute("isOpen", true)}
                            />
                          )}
                          <h6 className="m-0">
                            <FormattedMessage
                              id={`Task`}
                              defaultMessage={`Task`}
                            />
                            # {key + 1}
                          </h6>{" "}
                          <div style={{ width: 10 }} />
                          {task.isPending && (
                            <div
                              onClick={() => item.removeSpecificTask(task.__id)}
                              style={{ cursor: "pointer" }}
                            >
                              {DeleteIconRed}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: task.isOpen ? "flex" : "none",
                        }}
                      >
                        <Row>
                          <Col md="6" sm="12">
                            <div
                              className="dates"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Label className="form_label">
                                <FormattedMessage
                                  id="Site"
                                  defaultMessage={"Site"}
                                />
                              </Label>
                            </div>
                            <Select
                              name="sites"
                              value={task.site}
                              theme={selectThemeColors}
                              className="react-select"
                              classNamePrefix="select"
                              isLoading={item.siteLoading}
                              placeholder={intl.formatMessage({
                                id: item.siteLoading ? "Loading..." : "Select",
                                defaultMessage: item.siteLoading
                                  ? "Loading..."
                                  : "Select",
                              })}
                              onChange={(e) => {
                                task.setAttribute("site", e);
                              }}
                              onInputChange={debounce(
                                (inputValue, { action }) => {
                                  if (action === "input-change") {
                                    item.getAvailableSites(inputValue);
                                  }
                                },
                                1000
                              )}
                              options={filteredSiteOptions()}
                              menuPlacement="top"
                              isDisabled={!task.isPending}
                            />
                            <ValidationHandler isValid={task?.site} />
                          </Col>{" "}
                          <Col style={{ marginTop: -4 }} md="6" sm="12">
                            <Label className="form_label">
                              <FormattedMessage
                                id="inspector_name"
                                defaultMessage={"Inspector Name"}
                              />
                            </Label>
                            <Select
                              name="inspector"
                              value={task.inspector}
                              theme={selectThemeColors}
                              className="react-select"
                              classNamePrefix="select"
                              isLoading={item.inspectorsLoading}
                              placeholder={intl.formatMessage({
                                id: item.inspectorsLoading
                                  ? "Loading..."
                                  : "Select",
                                defaultMessage: item.inspectorsLoading
                                  ? "Loading..."
                                  : "Select",
                              })}
                              onChange={(e) => {
                                task.setAttribute("inspector", e);
                              }}
                              onInputChange={debounce(
                                (inputValue, { action }) => {
                                  if (action === "input-change") {
                                    item.getInspectors(inputValue);
                                  }
                                },
                                1000
                              )}
                              options={item.inspectors?.map((item) => {
                                return {
                                  ...item,
                                  label: getTranslation(
                                    item.name_ar || item.name
                                  ),
                                };
                              })}
                              menuPlacement="top"
                              isDisabled={!task.isPending}
                            />
                            <ValidationHandler isValid={task?.inspector} />
                          </Col>{" "}
                        </Row>
                      </Row>

                      {/* {task.availabilityStatus?.color && (
                            <div>
                              <span
                                style={{
                                  color:
                                    task.availabilityStatus?.color ||
                                    "transparent",
                                }}
                              >
                                {task.availabilityStatus?.msg}
                              </span>
                            </div>
                          )} */}
                    </CardBody>
                  </Card>
                );
              })}
            </>
          )}
        </Fragment>
      )}

      <Card className="shadow-none mt-2 auto_save">
        <CardBody>
          <Row>
            <Col sm="12">
              {item.isReview ? (
                <div className="d-flex flex-row-reverse">
                  <Submit />
                  <Button.Ripple
                    className="round btn mx-3 button_style"
                    onClick={(e) => {
                      e.preventDefault();
                      item.setIsReview(false);
                    }}
                    color="primary"
                  >
                    <span style={{ color: "white" }}>
                      {ChevronLeftWhite}
                      <span style={{ marginRight: 12 }}>
                        <FormattedMessage id="Back" defaultMessage={"Back"} />{" "}
                      </span>
                    </span>
                  </Button.Ripple>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    {!item.siteAvailablityStatus && (
                      <div style={{ color: "red" }}>
                        {item.siteAvailablityMsg}
                      </div>
                    )}
                  </div>
                  <div>
                    <Button.Ripple
                      className="round btn mx-3 button_style"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/Schedule-Medicine-Availability");
                      }}
                      color="primary"
                      outline
                    >
                      <span style={{ marginRight: 10 }}>
                        <span>{ChevronLeftGreen}</span>
                        <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                      </span>
                    </Button.Ripple>
                    <Button.Ripple
                      className="round btn button_style"
                      onClick={(e) => {
                        e.preventDefault();
                        item.review();
                      }}
                      color="primary"
                      disabled={!item.siteAvailablityStatus}
                    >
                      <span style={{ color: "white", marginLeft: 10 }}>
                        <FormattedMessage
                          id={"Review"}
                          defaultMessage={"Review"}
                        />
                        <span>{ChevronRightWhite}</span>
                      </span>
                    </Button.Ripple>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
});

export default ScheduleMedicineAvailability;
