import moment from "moment";
import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import ButtonGreen from "../../../../components/buttons/ButtonGreen";
import { observer } from "mobx-react";
import './historyModal.scss'

function HistoryModal({ modal, toggle, taskSelected }) {

  return (
    <Modal isOpen={modal} toggle={toggle} unmountOnClose={true} style={{'--modal-max-width': '30vw'}}>
      <ModalHeader toggle={toggle}>History</ModalHeader>
      <ModalBody>
        {taskSelected?.map((his, ind) => {
          return (
            <div key={ind} className="task-history">
              <p className="time">{moment(his.created_at).format("ll")}</p>
              <p className="message">
                {his.message}
                {!!his.reason && typeof his.reason == "string" && (
                  <>
                    <UncontrolledTooltip
                      placement="top"
                      target={"id" + his._id}
                    >
                      {his.reason}
                    </UncontrolledTooltip>
                    <span
                      style={{ color: "red", marginLeft: 10 }}
                      id={"id" + his._id}
                    >
                      Message
                    </span>
                  </>
                )}
              </p>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <ButtonGreen onClick={toggle} title="Close" />
      </ModalFooter>
    </Modal>
  );
}

export default observer(HistoryModal);
