import { ApprovedStatusIcon, CompletedStatusIcon, DeleteIcon, HistoryIcon, InfoIcon, NotCompletedStatusIcon, PendingStatusIcon, RejectedStatusIcon } from "../assets/wasfaty/SVG";

export const dropdownValuesForSelfAssessmentFilter = [
  { key: 'all', value: 'All' },
  { key: 'date', value: 'Date' },
  { key: 'regions', value: 'Region' },
  { key: 'clusters', value: 'Clusters' },
  { key: 'sites', value: 'Sites' },
  { key: 'forms', value: 'Form' }
];

export const dropdownValuesForSelfAssessmentModalFilter = [
  { key: 'all', value: 'All' },
  { key: 'sites', value: 'Sites' },
  { key: 'inspectors', value: 'Inspectors' }
];

export const dropdownValuesForMedicineAvailabilityFilter = [
  { key: 'all', value: 'All' },
  { key: 'regions', value: 'Region' },
  { key: 'clusters', value: 'Clusters' },
  { key: 'supervisors', value: 'Supervisors' },
  { key: 'date', value: 'Due Date' },
];

export const dropdownValuesForMedicineAvailabilityModalFilter = [
  { key: 'all', value: 'All' },
  { key: 'clusters', value: 'Clusters' },
  { key: 'sites', value: 'Sites' }
];



export const dropdownTableValues = [
  { key: 'delete', value: 'Delete', icon: DeleteIcon },
];

export const dropdownTableModalValues = [
  { key: 'history', value: 'History', icon: HistoryIcon }
];

export const statusIcons = [
  { name: 'Completed', isCompleted: true, color: '#38C793', bgColor: '#EFFAF6', icon: CompletedStatusIcon },
  { name: 'Not Completed', isCompleted: false, color: '#DF1C41', bgColor: '#FDEDF0', icon: NotCompletedStatusIcon },
];

export const statusIconsForModal = [
  { name: 'accepted', bgColor: '#35B9E9' },
  { name: 'approved', bgColor: '#38C793' },
  { name: 'cancelled', bgColor: '#868C98' },
  { name: 'initiated', bgColor: '#375DFB' },
  { name: 'pending', bgColor: '#F2AE40' },
  { name: 'rejected', bgColor: '#DF1C41' },
  { name: 'submitted', bgColor: '#6E3FF3' },
  { name: 'reopen approved', bgColor: '#38C793' },
  { name: 'reopen rejected', bgColor: '#DF1C41' },
  { name: 'reopen', bgColor: '#d67628' },
];

export const statusIconsForMedicineAvailabilityModal = [
  { name: 'accepted', bgColor: '#35B9E9' },
  { name: 'supervisor approved', bgColor: '#38C793' },
  { name: 'supervisor rejected', bgColor: '#DF1C41' },
  { name: 'regional approved', bgColor: '#38C793' },
  { name: 'regional rejected', bgColor: '#DF1C41' },
  { name: 'cancelled', bgColor: '#868C98' },
  { name: 'initiated', bgColor: '#375DFB' },
  { name: 'pending', bgColor: '#F2AE40' },
  { name: 'submitted', bgColor: '#6E3FF3' },
  { name: 'reopen approved', bgColor: '#38C793' },
  { name: 'reopen rejected', bgColor: '#DF1C41' },
  { name: 'reopen', bgColor: '#d67628' },
];

export const paginationPagesOptions = [
  { key: '5', value: '5' },
  { key: '10', value: '10' },
  { key: '15', value: '15' },
  { key: '20', value: '20' },
]

export const customStylesForSelfAssessmentTable = {
  headRow: {
    style: {
      borderBottomStyle: 'none',
      borderRadius: '8px',
      paddingTop: '15px',
    },
  },
  headCells: {
    style: {
      backgroundColor: '#F6F8FA',
      fontWeight: '500',
      fontSize: '14px',
      height: '36px',

    },
  },
  rows: {
    style: {
      // "&:hover": {
      //   background: "#efefef",
      // },
      minHeight: '52px',
      '&:nth-of-type(even)': {
        backgroundColor: '#F6F8FA',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
      },
    },
  },
  cells: {
    style: {
      paddingRight: '8px',
    },
  },
};

export const customStylesForSelfAssessmentModalTable = {
  tableWrapper: {
    style: {
      border: '1px solid #E2E4E9',
      borderRadius: '8px',
      overflow: 'hidden',
    },
  },
  headRow: {
    style: {
      borderBottomStyle: 'none',
      borderRadius: '8px',
      minHeight: '34px', 
    },
  },
  headCells: {
    style: {
      backgroundColor: '#F6F8FA',
      color: "var(--text-sub-500, #525866)",
      fontFeatureSettings: "'ss11' on, 'cv09' on, 'liga' off, 'calt' off",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "uppercase"
    },
  },
  rows: {
    style: {
      // "&:hover": {
      //   background: "#efefef",
      // },
      minHeight: '60px',
    },
  },
  cells: {
    style: {
      paddingRight: '8px',
    },
  },
};

export const dummyData = [
  {
    formName: 'Pharmacies Regular Visit',
    region: 'Riyadh',
    numberOfTask: '04',
    dateFrom: '15 May 2021',
    dateTo: '15 Aug 2021',
    status: 'Completed',
  },
  {
    formName: 'Pharmacies Regular Visit',
    region: 'Riyadh',
    numberOfTask: '04',
    dateFrom: '15 May 2021',
    dateTo: '15 Aug 2021',
    status: 'Not Completed',
  },
  {
    formName: 'Pharmacies Regular Visit',
    region: 'Riyadh',
    numberOfTask: '04',
    dateFrom: '15 May 2021',
    dateTo: '15 Aug 2021',
    status: 'Completed',
  },
  {
    formName: 'Pharmacies Regular Visit',
    region: 'Riyadh',
    numberOfTask: '04',
    dateFrom: '15 May 2021',
    dateTo: '15 Aug 2021',
    status: 'Not Completed',
  },
  {
    formName: 'Pharmacies Regular Visit',
    region: 'Riyadh',
    numberOfTask: '04',
    dateFrom: '15 May 2021',
    dateTo: '15 Aug 2021',
    status: 'Completed',
  },
]

export const dummyDataForModal = [
  {
    cluster: 'Central Region',
    facilityType: 'National Healthcare',
    facilityManager: 'AL-Badar',
    site: 'Central Hospital',
    licenceNumber: '384765873456',
    reason: 'NOT ANY',
    status: 'Approved'
  },

  {
    cluster: 'Central Region',
    facilityType: 'National Healthcare',
    facilityManager: 'AL-Badar',
    site: 'Central Hospital',
    licenceNumber: '384765873456',
    reason: 'NOT ANY',
    status: 'Completed'
  },

  {
    cluster: 'Central Region',
    facilityType: 'National Healthcare',
    facilityManager: 'AL-Badar',
    site: 'Central Hospital',
    licenceNumber: '384765873456',
    reason: 'NOT ANY',
    status: 'Not Completed'
  },

  {
    cluster: 'Central Region',
    facilityType: 'National Healthcare',
    facilityManager: 'AL-Badar',
    site: 'Central Hospital',
    licenceNumber: '384765873456',
    reason: 'NOT ANY',
    status: 'Pending'
  },

  {
    cluster: 'Central Region',
    facilityType: 'National Healthcare',
    facilityManager: 'AL-Badar',
    site: 'Central Hospital',
    licenceNumber: '384765873456',
    reason: 'NOT ANY',
    status: 'Rejected'
  },
 
]