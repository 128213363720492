import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";

import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import "./newStyle.scss";
import forgetPasswordAvatar from "./assets/forgetPasswordAvatar.png";
import { SC } from "../wasfaty/Api/serverCall";
import { IntlService } from "../wasfaty/services";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { rtlSupportStyles } from "./authThemeConfig";
import { eye, inVlaidicon, lock, valid } from "./assets/svg";

const ResetPasswordV1 = () => {
  const [Data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPass, setshowPass] = useState(false);

  let navigate = useNavigate();
  let { id } = useParams();
  let url = window.location.pathname;
  const params = useParams();
  let token = params.token;

  useEffect(() => {
    localStorage.clear();

    setData({
      ...Data,
      email: params.email,
    });
  }, []);
  const handleChange = (key, value) => {
    setValidation(false);
    setData({ ...Data, [key]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: Data.email,
      password: Data.password,
      token: token,
      password_confirmation: Data.password,
    };

    if (Data.password && validation) {
      setLoading(true);
      SC.postCall({ url: "reset-password", data }).then((res) => {
        if (res.status === 200) {
          toast.success("Password Changed Successfully!");
          navigate("/login");
        } else {
          toast.error(res.response?.data?.data?.[0]);
        }
        setLoading(false);
      });
    }
  };
  return (
    <div
      className="authLayout_container "
      style={{ backgroundColor: "#F6F8FA" }}
      dir={IntlService.isRtl ? "rtl" : "ltr"}
    >
      <div className="form_section w-100">
        <Header />
        <div className="card-body">
          <Form
            className="d-flex justify-content-center card-content"
            onSubmit={handleSubmit}
          >
            <div className="login_form">
              <div className="login-header gap-2 mb-1">
                <img src={forgetPasswordAvatar} width={110} />

                <h1 className="text-black">Change Password</h1>
              </div>

              <div className="text-black">
                <p className="heading">{IntlService.m("Change Password")}</p>
                <p className="info">
                  {IntlService.m(
                    "Update password for enhanced account security."
                  )}
                </p>
              </div>
              <hr className="line-devider" />

              <div>
                <div className="mb-1">
                  <Label className="lables">{IntlService.m("Password*")}</Label>
                  <InputGroup>
                    <figure
                      style={{
                        position: "absolute",
                        top: 8,
                        right: IntlService.isRtl ? 12 : null,
                        left: IntlService.isRtl ? null : 12,
                        zIndex: 999,
                      }}
                    >
                      {lock}
                    </figure>

                    <Input
                      autoComplete="new-password"
                      type={showPass ? "text" : "password"}
                      className="loginFields relative"
                      placeholder="Password"
                      value={Data.password}
                      autoFocus
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                      onChange={(e) =>
                        handleChange(
                          "password",
                          e.target.value.replace(/ /g, "")
                        )
                      }
                    />
                    <figure
                      className="position-absolute cursor-pointer"
                      onClick={() => {
                        setshowPass((prev) => !prev);
                      }}
                      style={{
                        zIndex: 999,

                        top: 8,
                        right: IntlService.isRtl ? null : 10,
                        left: IntlService.isRtl ? 10 : null,
                      }}
                    >
                      {eye}
                    </figure>
                  </InputGroup>
                </div>

                <div className="mb-1">
                  <Label className="lables">
                    {IntlService.m("Confirm Password*")}
                  </Label>
                  <InputGroup>
                    <figure
                      style={{
                        position: "absolute",
                        top: 8,
                        right: IntlService.isRtl ? 12 : null,
                        left: IntlService.isRtl ? null : 12,
                        zIndex: 999,
                      }}
                    >
                      {lock}
                    </figure>

                    <Input
                      autoComplete="new-password"
                      type={showPass ? "text" : "password"}
                      className="loginFields relative"
                      placeholder="Confirm Password"
                      value={Data.confirmPassword}
                      autoFocus
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                      onChange={(e) =>
                        handleChange(
                          "confirmPassword",
                          e.target.value.replace(/ /g, "")
                        )
                      }
                    />
                    <figure
                      className="position-absolute cursor-pointer"
                      onClick={() => {
                        setshowPass((prev) => !prev);
                      }}
                      style={{
                        zIndex: 999,

                        top: 8,
                        right: IntlService.isRtl ? null : 10,
                        left: IntlService.isRtl ? 10 : null,
                      }}
                    >
                      {eye}
                    </figure>
                  </InputGroup>
                </div>

                <div className="my-2">
                  <PasswordStrengthBar password={Data.password} />
                  <PasswordChecklist
                    rules={["minLength", "number", "capital", "match"]}
                    minLength={8}
                    value={Data.password}
                    valueAgain={Data.confirmPassword}
                    onChange={(isValid) => {
                      setValidation(isValid);
                    }}
                    iconComponents={{
                      ValidIcon: valid,
                      InvalidIcon: inVlaidicon,
                    }}
                  />
                </div>
              </div>

              <div className="d-flex gap-5">
                <Button
                  color="primary button-main"
                  block
                  outline
                  className="p-1 discardButton "
                  onClick={() => {
                    setData({ email: "", password: "", confirmPassword: "" });
                    setValidation(false);
                  }}
                >
                  {IntlService.m("Discard")}
                </Button>
                <Button
                  type="submit"
                  color="primary button-main"
                  block
                  className="p-1 authButton"
                >
                  {!loading ? (
                    IntlService.m("Apply Changes")
                  ) : (
                    <>
                      <span>Submitting...</span>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPasswordV1;
