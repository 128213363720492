import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonDropdown,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MoreVertical } from "react-feather";
import { SC } from "../../components/wasfatyComp/Api/serverCall";
function LogoutDropdown() {
  const navigate = useNavigate();
  const logOut = (e) => {
    e.preventDefault();
    navigate("/login");
    SC.getCall({ url: "logout" }).then((response) => {});
    localStorage.clear();
  };

  return (
    <>
      {/* <UncontrolledButtonDropdown>
        <DropdownToggle color="transparent">
          <MoreVertical />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem href="/" tag="a">
            Profile
          </DropdownItem>
          <DropdownItem href="/" tag="a" onClick={logOut}>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown> */}
    </>
  );
}

export default LogoutDropdown;
