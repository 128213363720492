import { autorun } from "mobx";

import React, { createRef } from "react";
import {
  makeAutoObservable,
  toJS,
  reaction,
  observe,
  makeObservable,
  observable,
  isObservable,
} from "mobx";
import { computed } from "mobx";
import get from "lodash/get";
import clone from "lodash/clone";
import Staff from "./Staff";
import startCase from "lodash/startCase";

import {
  phoneValidator,
  emailValidator,
  getValidPhone,
  StepsAlert,
} from "../Helper/utils";
import { current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Inspector from "./InspectorModel";
import FileUploadModal from "./FileUploadModel";
import cloneDeep from "lodash/cloneDeep";
import { AuthService, IntlService, ModuleService } from "../services";

import MedicineModel from "./MedicineSelectionModel";
import { SC } from "../Api/serverCall";
import { getUserData } from "@utils";
import { scrollToRequired } from "../Helper/utils";
import DraftModal from "./DraftModel";
import TaskModal from "./TaskModal";
import Violation from "./Violation";
import ComplianceMember from "./ComplianceMemberModel";
import AuthUser from "../services/AuthService";

export class ModalMixin {
  static setupCommonActions() {
    // Computed properties
    [
      "isIspectorsAddable",
      "isIspectorsRemoveable",
      "isFirstStep",
      "isLastStep",
      "isPharmacyAvailable",
      "pharmacyNotAvailable",
      "isCbahiCertifiedNo",
      "isSpecializationClassifiedNo",
      "isFinalStep",
      "isIdentification",
      "identificationStep",
      "isAdditionalRemarks",
      "iscompliance_informations",
      "isStaffAvailability",
      "selectedDepartments",
      "departmentList",
      "allDeparment",
      "isMaxDepartmentLimitReached",
    ].forEach((method) => {
      Object.defineProperty(this, method, {
        get: ModalMixin[method],
        configurable: true,
      });
    });

    //Methods
    [
      "setRegion",
      "setAttribute",
      "setValidation",
      "setIsSubmit",
      "setIsReview",
      "hasDuplicatesInspectorEmail",
      "hasDuplicatesInspectorPhone",
      "isInspectorsValid",
      "canAddInspector",
      "addInspector",
      "removeInspector",
      "addComplianceMembers",
      "removeComplianceMembers",
      "setDate",
      "setUserId",
      "setPayload",
      "isDropDown",
      "getCurrentStep",
      "previousStep",
      "addStaff",
      "removeStaff",
      "allSteps",
      "missingSteps",
    ].forEach((method) => {
      Object.defineProperty(this, method, {
        value: ModalMixin[method].bind(this),
        configurable: true,
      });
    });

    // initializing compliance states
    this.complianceMembers = [];
    this.compDelegatedPerson = "";
    this.compIdNumber = "";
    this.compIssueDate = "";
    this.compIssuePlace = "";
    this.composition = "";
    this.compSignature = new ComplianceMember(null, true);
  }

  static getCurrentStep() {
    return this.stepper?._steps[
      this.stepper?._currentIndex
    ]?.dataset?.target?.replace("#", "");
  }

  static isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: IntlService.m(value), value };
    }

    return value;
  }

  static setAttribute(name, value) {
    // if (name == "region") this.setRegion(value);
    if (name) {
      this[name] = value;
    }

    autorun(() => {})();
  }

  static setRegion(value) {
    if (value?._id) {
      this.region = value;
    }
  }

  static setValidation(check) {
    this.isValidation = check;
  }

  static setIsSubmit(check) {
    this.isSubmit = check;
  }

  static setIsReview(check) {
    this.isReview = check;
  }

  static hasDuplicatesInspectorEmail() {
    let a = this.inspectors
      .filter((inspector) => inspector.email)
      .map((inspector) => {
        return inspector.email;
      });
    const noDups = new Set(a);
    return a.length !== noDups.size;
  }

  static hasDuplicatesInspectorPhone() {
    let a = this.inspectors
      .filter((inspector) => inspector.phone)
      .map((inspector) => {
        return inspector.phone;
      });
    const noDups = new Set(a);
    return a.length !== noDups.size;
  }

  static isInspectorsValid() {
    if (this.task) return true;
    return this.inspectors.reduce((result, inspector) => {
      inspector.setValues();
      return result && inspector.isValid();
    }, !this.hasDuplicatesInspectorEmail() && !this.hasDuplicatesInspectorPhone());
  }

  static canAddInspector() {
    return true;
    return this.inspectors.length < 20;
  }

  static addInspector() {
    if (this.inspectors.length < 20) {
      this.inspectors.push(new Inspector());
    } else {
      toast.error(
        IntlService.formatMessage("Maximum 20 inspectors are allowed")
      );
    }
  }
  static addComplianceMembers() {
    if (this.complianceMembers.length < 20) {
      this.complianceMembers.push(new ComplianceMember());
    } else {
      toast.error(
        IntlService.formatMessage("Maximum 20 compliance Members are allowed")
      );
    }
  }

  static removeComplianceMembers(inspector) {
    if (this.complianceMembers.length > 1) {
      this.complianceMembers.pop();
    } else {
      toast.error(
        IntlService.formatMessage("Minimum 1 compliance Members required")
      );
    }
  }
  static removeInspector(inspector) {
    if (this.inspectors.length > 1) {
      this.inspectors.pop();
    } else {
      toast.error(IntlService.formatMessage("Minimum 1 inspector required"));
    }
  }

  static setDate(payload) {
    ["license_issue_date", "license_expiry_date", "compIssueDate"].forEach(
      (att) => {
        let date = payload[att];
        this[att] = new Date();
        if (date) {
          this[att] = new Date(date);
        }
      }
    );
  }

  static setUserId(user) {
    this.user_id = user._id;
    this.user = user;
    this.auditor_email = user.email;
    this.auditor_phone = getValidPhone(user.phone);
  }

  static setPayload(payload) {
    if (payload) {
      const {
        region,
        site,
        inspectorPhone,
        staff_availability,
        inspectors,
        auditor_phone,
        image,
        violations,
        obligation_site,
        complianceMembers,
        compSignature,
      } = payload;

      Object.entries(payload).forEach(([name, value]) => {
        if (["site", "region", "image"].includes(name)) return;

        if (name.includes("_file")) {
          this[name] = new FileUploadModal(value);
          this[name].setImages(value);
        } else {
          if (this[name] !== undefined) {
            this.setAttribute(name, this.isDropDown(name, value));
          }
        }
      });

      if (!this.task) {
        if (region) {
          this.region = { ...region, value: region._id, label: region.name };
        }

        if (site) {
          let label = `${site.licenseNumber || site.license}`;
          label = label + (site.name_ar ? ` - ${site.name_ar}` : "");
          label = label + (site.name ? ` - ${site.name}` : "");
          label = label + (site.street_name ? ` - ${site.street_name}` : "");
          this.site = { ...site, value: site._id, label };
        }

        if (obligation_site) {
          this.site = {
            ...obligation_site,
            name: obligation_site.name || obligation_site.name_ar,
            value: obligation_site._id,
            label:
              obligation_site.label ||
              obligation_site.name_ar ||
              obligation_site.name,
          };
        }
      }

      if (inspectors?.length) {
        this.inspectors = [];
        inspectors.forEach((inspector) => {
          this.inspectors.push(new Inspector(inspector));
        });
      }
      if (complianceMembers?.length) {
        this.complianceMembers = [];
        complianceMembers.forEach((mem) => {
          this.complianceMembers.push(new ComplianceMember(mem));
        });
      }
      // if (compSignature) {
      this.compSignature = new ComplianceMember(
        { signature: compSignature },
        true
      );
      // }

      if (staff_availability?.length) {
        this.staff_availability = [];
        staff_availability.forEach((staff) => {
          this.staff_availability.push(new Staff(staff));
        });
      }

      if (violations?.length) {
        this.violations = [];
        violations.forEach((violation) => {
          this.violations.push(new Violation(violation));
        });
      }

      this.inspectorPhone = getValidPhone(inspectorPhone);

      if (auditor_phone) {
        this.auditor_phone = getValidPhone(auditor_phone);
      }
      if (!this.skipFileImage) {
        this.image = new FileUploadModal(image);
      } else {
        this.image = image;
      }

      this.setDate(payload);
      this.test = !this.test;
      this.afterSetPayload?.(payload);
    }

    this.test = !this.test;
  }

  static allSteps() {
    return Object.entries(this.formFields).map(([k]) => {
      return {
        isCompleted: !this.missingSteps().includes(k),
        title: k,
        attribute: k,
      };
    });
  }

  static missingSteps() {
    return Object.entries(this.formFields)
      .map(([key, attributes]) => {
        let filled = attributes.reduce((result, item) => {
          return result && this[item.attribute];
        }, true);

        if (key === "identification") {
          filled = this.chekIdentificationValid();
        }

        if (key === "staff_availability") {
          filled = this.staff_availability.reduce(
            (result, staff) => result && staff.isValid(),
            true
          );
        }

        if (key === "additionalRemarks") {
          if (
            attributes
              .map((attribute) => attribute.type)
              .includes("compliance_informations")
          ) {
            filled = this?.complianceMembers?.reduce(
              (result, staff) => result && staff.isValid(),
              true
            );

            filled =
              filled &&
              this.compDelegatedPerson &&
              this.compIdNumber &&
              this.compIssueDate &&
              this.compIssuePlace &&
              this.composition &&
              this.compSignature?.signature?.length;
          }
        }

        if (!filled) {
          return key;
        }
      })
      .filter((i) => i);
  }

  static setupTask(task) {
    if (task) {
      this.isEdit = !!task.data;
      this.class_name = task._id;
      this.task = new TaskModal(task);

      let allAvailableSteps = Object.entries(this.formFields).map(
        ([key]) => key
      );
      let stepsNames = task.steps.map(({ value }) => value);
      allAvailableSteps.forEach((stepName) => {
        if (!stepsNames.includes(stepName)) {
          delete this.formFields[stepName];
        }
      });
      let site = task?.schedule?.site;
      if (site) {
        let label = `${site.licenseNumber || site.license}`;
        label = label + (site.name_ar ? ` - ${site.name_ar}` : "");
        label = label + (site.name ? ` - ${site.name}` : "");
        label = label + (site.street_name ? ` - ${site.street_name}` : "");

        //Site and region only can be set by admin in the schdule creation step
        this.site = {
          ...site,
          label,
          value: site?._id,
        };
      }

      this.region = {
        ...task?.schedule?.region,
        label: task?.schedule?.region?.name,
        value: task?.schedule?.region?._id,
      };

      if (task.data) {
        this.init(task.data);
      }
    }
  }

  static setupFormOptions(slug) {
    let form_options = ModuleService.getFormOptionsBySlug(slug);
    form_options = Object.keys(form_options).length
      ? JSON.parse(JSON.stringify(form_options))
      : {};

    if (AuthUser.isFacility) {
      delete form_options.additionalRemarks;
    }
    this.formFields = form_options;
    this.orignalFormOptions = clone(form_options);

    Object.entries(this.formFields).forEach(([key, attributes]) => {
      attributes.forEach(({ attribute, isSkip }) => {
        this[attribute] = "";
        this[attribute + "_other"] = "";
        this[attribute + "_file"] = new FileUploadModal();
      });
    });

    if (!this.isEdit && !this.skipFileImage) {
      this.image = new FileUploadModal();
    }
  }

  static previousStep() {
    this.stepper.previous();
    this.test = !this.test;
  }

  static addStaff() {
    this.staff_availability.push(new Staff());
  }

  static removeStaff(staff) {
    if (staff) {
      this.staff_availability = this.staff_availability.filter(
        (_staff) => _staff.__id !== staff.__id
      );
    } else {
      this.staff_availability.pop();
    }
  }

  // Computed properties

  static isIspectorsAddable() {
    return this.inspectors.length < 2;
  }

  static isIspectorsRemoveable() {
    return this.inspectors.length > 1;
  }

  static isFirstStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === 0;
  }

  static isLastStep() {
    return this.isFinalStep;
  }

  static isPharmacyAvailable() {
    return (
      (this.available_on_ground || this.is_the_pharmacy_available) === "Yes"
    );
  }

  static isPharmacyAvailable() {
    return (
      (this.available_on_ground || this.is_the_pharmacy_available) === "Yes"
    );
  }

  static pharmacyNotAvailable() {
    return (
      (this.available_on_ground || this.is_the_pharmacy_available) === "No"
    );
  }

  static isCbahiCertifiedNo() {
    return this.cbahi_certified === "No";
  }
  static isSpecializationClassifiedNo() {
    return this.is_specialization_classified === "No";
  }

  static isFinalStep() {
    if (this.test) {
    }
    return this.stepper?._currentIndex === this.stepper?._steps?.length - 1;
  }

  static isIdentification() {
    return !!this.formFields["identification"];
  }

  static isAdditionalRemarks() {
    return !!this.formFields["additionalRemarks"];
  }
  static iscompliance_informations() {
    return this.formFields["additionalRemarks"].some(
      (obj) => obj?.attribute === "compliance_informations"
    );
  }

  static isStaffAvailability() {
    // let check = true;
    // if (this.staff_availability?.length) {
    //   check = this.staff_availability.reduce((result, staff) => {
    //     return result && staff.isValid();
    //   }, true);
    // }

    return !!this.formFields["staff_availability"];
  }

  static identificationStep() {
    return this.stepper?._steps
      ?.map?.((s) => s.dataset?.target)
      .indexOf?.("#identification");
  }

  static selectedDepartments() {
    if (Array.isArray(this.staff_availability)) {
      return this.staff_availability.map(
        ({ department }) => department?.label || department
      );
    }

    return [];
  }

  static departmentList() {
    return Object.entries(this.orignalFormOptions)
      .map(([key, attributes]) => {
        if (
          [
            "identification",
            "staff_availability",
            "additionalRemarks",
          ].includes(key)
        ) {
          return null;
        }

        return {
          label: startCase(key),
          value: startCase(key),
        };
      })
      .filter((i) => i);
  }

  static allDeparment() {
    return this.departmentList.filter(
      (deparment) => !this.selectedDepartments.includes(deparment.label)
    );
  }
  static isMaxDepartmentLimitReached() {
    return this.staff_availability?.length >= this.departmentList?.length;
  }
}
