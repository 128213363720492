// src/components/RoleList.js
import React from 'react';

const RoleList = ({ roles, onEdit, onDelete }) => {
  return (
    <div>
      <h2>Role List</h2>
      <ul>
        {roles.map((role) => (
          <li key={role.id}>
            {role.name} 
            <button onClick={() => onEdit(role)}>Edit</button>
            <button onClick={() => onDelete(role.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoleList;
