import { makeAutoObservable, autorun, toJS, reaction, observe } from "mobx";
import { v4 as uuidv4 } from "uuid";

class Violation {
  __id = null;
  code = "";
  item = "";
  Violations = "";
  regulatory_item = "";
  note = "";

  pdfObject = [
    {
      title: "Code",
      attribute: "code",
    },

    {
      title: "Item",
      attribute: "item",
    },
    {
      title: "Violations",
      attribute: "Violations",
    },
    {
      title: "Regulatory Item",
      attribute: "regulatory_item",
    },
    {
      title: "Note",
      attribute: "note",
    },
  ];

  constructor(violation) {
    this.__id = uuidv4();
    if (violation) {
      this.code = violation.code;
      this.item = violation.item;
      this.Violations = violation.Violations;
      this.regulatory_item = violation.regulatory_item;
      this.note = violation.note;
    }
    makeAutoObservable(this);
  }

  isValid() {
    let check =
      this.code &&
      this.item &&
      this.Violations &&
      this.regulatory_item &&
      this.note;

    return check;
  }

  getPayload() {
    let payload = {};

    payload.code = this.code;
    payload.item = this.item;
    payload.Violations = this.Violations;
    payload.regulatory_item = this.regulatory_item;
    payload.note = this.note;

    return payload;
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }
}

export default Violation;
