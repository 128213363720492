import { Singleton, Resources } from "../Resource";

import { formFields } from "../Models/InspectionModal";
import moment from "moment";
import get from "lodash/get";

import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "scheduleMedicineAvailability";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    tasks: "/tasks",
    createTasks: "/createTasks",
    update: "/update",
    delete: "/delete",
    availableSites: '/get-available-sites'
  };

  constructor() {
    super(arguments);
  }

  getAvailableSites = async (params) => {
    try {
      let res = await SC.getCall({
        url: `${this.route}/available-sites`,
        params,
      });
      if (res?.data.data) {
        return res?.data
      }
    } catch (e) {
      return [];
    }
  };

  getAvailableDates = async () => {
    try {
      let res = await SC.getCall({
        url: `${this.route}/available-dates`,
      });
      if (res?.data?.status) {
        return res?.data.data
      }
    } catch (e) {
      return [];
    }
  };

  getUsersByRegionID = async (params) => {
    try {
      let res = await SC.getCall({
        url: `get-region-users`,
        params
      });
      let _data = get(res, "data.data");
      if (_data?.length) {
        return _data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  getMedicines = async (params) => {
    try {
      let res = await SC.getCall({
        url: `${this.route}/get-medicines`,
        params
      });
      let _data = get(res, "data.data");
      if (_data?.length) {
        return _data
      }
      return []
    } catch (e) {
      return [];
    }
  }


}

const ScheduleMedicineAvailabilityService = new Service();
export default ScheduleMedicineAvailabilityService;