export const steperForm = [
  "Regular-Visit",
  "Specialized-Medical",
  "Private-Hospital",
  "General-Hospital",
  "General-Medical-Complex",
  "Phc-Audit",
  "Phc-Night-Shift",
  "Lab-Audit",
  "Dental-Lab",
];

export const moduleOptions = [
  "Medicine-Availability",
  "Self-Assessment",
  "Wasfaty-Audit",
  ...steperForm,
];


