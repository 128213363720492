import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Spinner,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { useState } from "react";
import { validateFields } from "./ValidateFields";
import toast from "react-hot-toast";
import { FieldValidation } from "./RenderFeedback";
import "./newStyle.scss";

import forgetPasswordAvatar from "./assets/forgetPasswordAvatar.png";
import { SC } from "../wasfaty/Api/serverCall";
import { rtlSupportStyles } from "./authThemeConfig";
import { IntlService } from "../wasfaty/services";
import { info, mail } from "./assets/svg";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ContentSection from "./Components/ContentSection";

const ForgotPasswordV1 = () => {
  const frogetPasswordObj = {
    email: "",
  };
  const frogetPasswordObj2 = {
    email: [],
  };
  const [Data, setData] = useState(frogetPasswordObj);
  const [validation, setValidation] = useState(frogetPasswordObj2);
  const [isValidationError, setIsValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
    handleValidationOnChange({ key: state, value });
  };
  const handleValidationOnChange = ({ key, value }) => {
    const response = validateFields({
      value: value,
      not: true,
      isEmail: key === "email",
    });
    setValidation({ ...validation, [key]: response });
  };
  const handleSubmit = async (data) => {
    data.preventDefault();
    if (loading) return;
    const final = {
      email: Data.email,
    };
    // VO === Validation Object
    let VO = {};
    // validating All required fields
    Object.keys(frogetPasswordObj2).map((item) => {
      const response = validateFields({
        value: final[item],
        not: true,
        isEmail: item === "email",
      });
      if (response?.length > 0) {
        VO = {
          ...VO,
          [item]: response,
        };
      }
    });
    if (Object.keys(VO).length) {
      // set validation
      setValidation(VO);
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      setLoading(true);

      {
        SC.postCall({ url: "forget-password", data: final }).then(
          (response) => {
            if (response?.status === 200 && response.data) {
              toast.success("Email Send successfully!");
              setData(frogetPasswordObj);
              // navigate("/check-reset");
            } else {
              toast.error(response?.response?.data?.message);
            }
            setLoading(false);
          }
        );
      }
    }
  };

  return (
    <div
      className="authLayout_container"
      dir={IntlService.isRtl ? "rtl" : "ltr"}
    >
      <div className="form_section">
        <Header />

        <Form className="d-flex justify-content-center">
          <div className="login_form">
            <div className="login-header">
              <img src={forgetPasswordAvatar} width={110} />
              <p className="heading">{IntlService.m("Reset Your Password")}</p>
              <p className="info">
                {IntlService.m("Submit your email for password reset.")}
              </p>
            </div>

            <hr className="line-devider" />
            <div className="mb-1">
              <Label className="lables">
                {IntlService.m("Email Address*")}
              </Label>
              <InputGroup>
                {/* <InputGroupText
                  className="loginFields"
                  style={
                    IntlService.isRtl
                      ? rtlSupportStyles.iconRtl
                      : rtlSupportStyles.iconLtr
                  }
                >
                  {mail}
                </InputGroupText> */}
                <Input
                  type="text"
                  className="loginFields relative"
                  placeholder={IntlService.m("Email")}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                  }}
                  value={Data.email}
                  onChange={(e) => {
                    handlechange("email", e.target.value.toLowerCase());
                  }}
                  invalid={isValidationError && validation.email?.length}
                />

                <figure
                  style={{
                    position: "absolute",
                    top: 8,
                    right: IntlService.isRtl ? 15 : null,
                    left: IntlService.isRtl ? null : 15,
                    zIndex: 999,
                  }}
                >
                  {mail}
                </figure>
              </InputGroup>
              {/* {isValidationError && (
                <FieldValidation
                  validations={validation.email}
                  isTransparent={true}
                />
              )} */}
              <p className="info-content mb-2">
                <div className="d-flex flex-row">
                  <span style={{ marginTop: -2, marginRight: 4 }}>{info}</span>
                  <p>
                    {IntlService.m(
                      "Kindly input your registered email address."
                    )}
                  </p>
                </div>
              </p>
            </div>

            <>
              <Button
                type="submit"
                // color="primary button-main"
                block
                className="p-1 authButton"
                onClick={handleSubmit}
                disabled={loading}
              >
                {!loading ? (
                  IntlService.m("Send Reset Link")
                ) : (
                  <>
                    <Spinner size="sm" />
                  </>
                )}
              </Button>

              <Button
                // color="primary button-main"
                block
                outline
                className="p-1 mt-2 backButton"
                // onClick={handleSubmit}
                disabled={loading}
                onClick={() => navigate("/login")}
              >
                {IntlService.m("Return to login")}
              </Button>
            </>
          </div>
        </Form>
        <Footer />
      </div>

      <ContentSection />
    </div>
  );
};

export default ForgotPasswordV1;
