// import { Link } from "react-router-dom";

import { Button, Container } from "reactstrap";
import PoweredByLogo from "./assets/MOH_C_AM.png";
import { Link } from "react-router-dom";
import "./style.scss";
import { infoIcon } from "../../assets/wasfaty/SVG";
import eficaXLogo from "../../assets/wasfaty/brandlogo.svg";

const Inbox = () => {
  return (
    <Container fluid className="authLayout">
      <div className="headercontent">
        <div>
          <img src={PoweredByLogo} alt="logo" style={{ width: "150px" }} />
        </div>
        {/* <div>
          <Language />
        </div> */}
      </div>
      <div className="check-email-screen">
        <div className="check-email-screen-icon">
          <figure>{infoIcon}</figure>
        </div>
        <div className="check-email-screen_box">
          <p>Check your inbox</p>
          <p>We have sent you instructions on how to </p>
          <p>reset your password.</p>
        </div>
        <div className="check-email-screen-custombtn">
          <Button
            type="submit"
            color="primary"
            block
            className="p-1 authButton"
            tag={Link}
            to={"/login"}
          >
            <span>Go Back</span>
          </Button>
        </div>
      </div>

      <a
        href="https://ascend.com.sa/"
        target="_blank"
        rel="noopener noreferrer"
        className="authLayout__footer"
      >
        {/* <img
          src={PoweredByLogo}
          alt="logo"
          className="authLayout__footer--logo"
        /> */}
      </a>
    </Container>
  );
};

export default Inbox;
