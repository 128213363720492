import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Button,
  InputGroup,
  Input,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import "./style.scss";

import {
  IntlService,
  ModuleService,
  RegionService,
  VsightService,
} from "../../../views/wasfaty/services";
import { Howl } from "howler";
import { useRef, useState, useEffect } from "react";
import { FiCopy } from "react-icons/fi";

import audioUrl from "../../../assets/audios/ringing-151670.mp3";
import { selectThemeColors } from "@utils";

import phone from "../../../assets/images/callIcon.gif";
import { PointSpreadLoading } from "react-loadingg";
import clipboardCopy from "clipboard-copy";
import toast from "react-hot-toast";
import {
  OBLIGATION,
  RegionSiteModal,
} from "../../../views/wasfaty/Models/RegionSiteModal";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import startCase from "lodash/startCase";

import Select from "react-select";

import {
  makeAutoObservable,
  autorun,
  toJS,
  makeObservable,
  observable,
} from "mobx";
class ExpertCall {
  region = "";
  site = "";
  form_name = "";
  isValidation = false;
  dynamicForms = [];

  constructor() {
    this.getDynamicForm();

    makeAutoObservable(this);
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  setFormName = (form_name) => {
    this.form_name = form_name;
  };

  isValid = () => {
    this.isValidation = true;

    return this.region && this.form_name;
  };
  getDynamicForm = () => {
    ModuleService.getDynamicForms().then((forms) => {
      // console.log("forms", forms);
      this.dynamicForms = forms;
    });
  };

  get steperForms() {
    return [
      ...ModuleService.getSteperForms().filter(
        (form) =>
          form.slug == "Regular-Visit" || form.slug == "Private-Hospital"
      ),
      ...this.dynamicForms,
    ];
  }
}

const ExpertCallDropDown = ({ navigate }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const urlRef = useRef(null);
  const sound = new Howl({
    src: audioUrl,
    autoplay: false, // Set to true if you want the sound to start playing immediately
    loop: true, // Set to true for continuous looping
    volume: 1.0, // Adjust the volume as needed (0.0 to 1.0)
  });
  const intl = useIntl();
  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  const [isInfo, setIsInfo] = useState(false);

  const [item, setItem] = useState(() => new ExpertCall());

  const [regions, setRegions] = useState([]);
  const [sitesModal] = useState(
    () => new RegionSiteModal({ siteType: OBLIGATION })
  );

  const region_id = item?.region?._id;

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    RegionService.getByMethod("Pharmacy").then(setRegions);
  };

  useEffect(() => {
    if (region_id) {
      sitesModal.getSites(region_id);
      if (region_id != item?.site?.region_id) item.setAttribute("site", null);
    }
  }, [region_id]);

  const setupCall = () => {
    setShow(true);
    setIsInfo(true);
  };

  const startCall = () => {
    setIsInfo(false);
    setLoading(true);
    let data = {
      slug: item.form_name?.slug,
      region_id: item.region?._id,
      site_id: item.site?._id,
      title:
        item.form_name?.name +
        " - " +
        (item.site?.name_ar || item.site?.name || "N/A") +
        " - " +
        item.region?.name,
    };
    sound.play();

    VsightService.create(data).then((res) => {
      setData(res.data);
      sound.stop();
      setLoading(false);

      urlRef?.current?.click();
      setItem(new ExpertCall());
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const ValidationHandler = observer(
    ({ isValid, message = `"This field is required!"` }) => {
      return (
        <>
          <Input type="text" hidden invalid={!isValid && item.isValidation} />
          <FormFeedback>
            <FormattedMessage id={message} defaultMessage={message} />
          </FormFeedback>
        </>
      );
    }
  );

  return (
    <>
      <Modal isOpen={show} centered>
        {isInfo ? (
          <>
            <ModalHeader
              toggle={() => {
                setShow(false);
              }}
            >
              <p style={{ fontSize: 16, fontWeight: 600, margin: 0 }}>
                {IntlService.m("Call Information")}
              </p>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" sm="12" className="mb-1">
                  <Label className="form-label">
                    <FormattedMessage
                      id="Select Form"
                      defaultMessage={"Select Form"}
                    />
                    *
                  </Label>
                  <Select
                    placeholder={intl.formatMessage({
                      id: "Select",
                      defaultMessage: "Select",
                    })}
                    onChange={(form) => {
                      sitesModal.setPath(form.value);
                      item.setAttribute("region", null);
                      item.setAttribute("site", null);

                      item.setFormName(form);
                    }}
                    // value={modal ? modal[attribute] : item[attribute]}
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    options={item.steperForms.map((module) => {
                      return {
                        ...module,
                        label: getTranslation(startCase(module.name)),
                        value: module.name,
                      };
                    })}
                    value={item.form_name}
                  />
                  <ValidationHandler isValid={item.form_name} />
                </Col>
                <Col className="mb-1" md="12" sm="12">
                  <Label className="form-label">
                    <FormattedMessage id="Regions" defaultMessage={"Regions"} />{" "}
                    *
                  </Label>
                  <Select
                    placeholder={intl.formatMessage({
                      id: "Select",
                      defaultMessage: "Select",
                    })}
                    name="regions"
                    onChange={(e) => {
                      item.setAttribute("region", e);
                      item.setAttribute("site", null);
                    }}
                    value={item.region}
                    isDisabled={item.task}
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    options={regions.map((item) => {
                      item.label = getTranslation(item.label);
                      return item;
                    })}
                  />
                  <ValidationHandler isValid={item.region} />
                </Col>{" "}
                <Col className="mb-1" md="12" sm="12">
                  <Label className="form-label">
                    <FormattedMessage id="Site" defaultMessage={"Site"} />
                  </Label>

                  <Select
                    isDisabled={!region_id || item.task}
                    placeholder={intl.formatMessage({
                      id: sitesModal.isLoading ? "Loading..." : "Select",
                      defaultMessage: sitesModal.isLoading
                        ? "Loading..."
                        : "Select...",
                    })}
                    name="site"
                    onChange={(e) => {
                      item.setAttribute("site", e);
                    }}
                    value={item.site}
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    options={sitesModal.sites.map((item) => {
                      item.label = getTranslation(item.label);
                      return item;
                    })}
                    isLoading={sitesModal.isLoading}
                    onInputChange={sitesModal.onSearch}
                  />
                  {item?.site?.longitude && item?.site?.longitude && (
                    <Label className="form-label mt-1">
                      Site map link {"\u00A0"}
                      <a
                        target={"_blank"}
                        href={`https://maps.google.com/?q=${item.site.latitude},${item.site.longitude}`}
                      >
                        Go To Direction
                      </a>
                    </Label>
                  )}

                  {/* <ValidationHandler isValid={item.site} /> */}
                </Col>
                <Col
                  md="12"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end",
                  }}
                >
                  <Button.Ripple
                    color="primary"
                    onClick={() => {
                      if (item.isValid()) {
                        startCall();
                      }
                    }}
                  >
                    {IntlService.m("Call")}
                  </Button.Ripple>
                </Col>
              </Row>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader
              toggle={() => {
                !loading
                  ? setShow(false)
                  : alert(
                      IntlService.m("Call cannot be canceled at this time.")
                    );
              }}
            >
              <p style={{ fontSize: 16, fontWeight: 600, margin: 0 }}>
                {IntlService.m("Connecting to Experts")}
              </p>
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <div>
                  <div style={{ height: 100 }}>
                    <PointSpreadLoading color="#10A945" />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-between align-items-center">
                  <InputGroup className="my-2">
                    <Input placeholder="url" value={data?.link} readOnly />
                    <Button.Ripple
                      color="primary"
                      onClick={() => {
                        clipboardCopy(data?.link);
                        toast.success(IntlService.m("URL Copied!"));
                      }}
                    >
                      <FiCopy />
                    </Button.Ripple>
                  </InputGroup>

                  <a
                    href={data?.link}
                    target="_blank"
                    rel="noopener"
                    className="btn btn-primary"
                    ref={urlRef}
                  >
                    {IntlService.m("Join")}
                  </a>
                </div>
              )}
            </ModalBody>
          </>
        )}
      </Modal>

      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        direction="left"
        id="expert-call"
      >
        <DropdownToggle color="white" className="m-0 p-0">
          <>
            <div>
              <UncontrolledTooltip placement="bottom" target={"tooltip-call"}>
                {IntlService.m("Call to Expert")}
              </UncontrolledTooltip>

              <div
                id="tooltip-call"
                style={{
                  top: 20,
                  left: 30,
                  position: "absolute",
                  height: 40,
                  width: 40,
                  // background: "red",
                }}
              ></div>

              <img src={phone} width="100" style={{ marginRight: -20 }} />
            </div>
          </>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem className="w-100" onClick={() => setupCall()}>
            <div className="d-flex align-items-center">
              {IntlService.m("Make Call")}{" "}
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="w-100"
            onClick={() => navigate("/Virtual-Sessions")}
          >
            <div className="d-flex align-items-center">
              {IntlService.m("History")}
            </div>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};

export default observer(ExpertCallDropDown);
