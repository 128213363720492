import { lazy, useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";

const ScheduleTask = lazy(() =>
  import("../../views/pages/Add/ScheduleTask/ScheduleTask")
);
const ScheduleAvailability = lazy(() =>
  import("../../views/pages/Add/ScheduleAvailability/ScheduleAvailability")
);
const ScheduleMedicalError = lazy(() =>
  import("../../views/pages/Add/ScheduleMedicalError/ScheduleMedicalError")
);

const Modules = lazy(() => import("../../views/pages/Add/Modules/Modules"));
const Regions = lazy(() => import("../../views/pages/Add/Regions/Regions"));
const RiskCompliance = lazy(() =>
  import("../../views/pages/Add/RiskCompliance/RiskCompliance")
);
const Translation = lazy(() =>
  import("../../views/pages/Add/Translation/Translation")
);
const Sites = lazy(() => import("../../views/pages/Add/Sites/Sites"));
const Clusters = lazy(() => import("../../views/pages/Add/Cluster/Cluster"));
const Users = lazy(() => import("../../views/pages/Add/User/User"));
const AdminMedicines = lazy(() =>
  import("../../views/pages/Add/AdminMedicines")
);
const SelfAssessment = lazy(() =>
  import("../../views/pages/Add/SelfAssessment/SelfAssessment")
);

// %templateImport%
const FormBuilder = lazy(() => import("../../views/DynamicForm/add"));
const MedicineAvailability = lazy(() =>
  import("../../views/pages/Add/MedicineAvailability")
);
import ScheduleSelfAssessment from "../../views/pages/Add/ScheduleSelfAssessment/ScheduleSelfAssessment";

import Cookies from "js-cookie";
import { SC } from "../../views/wasfaty/Api/serverCall";
import ScheduleMedicineAvailability from "../../views/pages/Add/ScheduleMedicineAvailability/ScheduleMedicineAvailability";
import RoleManagement from "../../views/pages/Add/AccessControlList/RoleManagement";
import RoleList from "../../views/pages/Add/AccessControlList/RoleList";
// import ScheduleMedicineAvailability from "../../views/pages/Add/ScheduleMedicineAvailability/ScheduleMedicineAvailability";

const PreOuth = () => {
  let isAuth = localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(isAuth);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onTaskSubmitSuccess = () => {
    setIsSubmitted(true);
  };

  useEffect(() => {
    let token = Cookies.get("accessToken");

    if (!isAuth && token) {
      (async () => {
        localStorage.setItem("accessToken", JSON.stringify(token));
        Cookies.remove("accessToken");
        SC.getCall({ url: "token" })
          .then((res) => {
            try {
              if (res?.data && res?.data?.data?.user[0]) {
                localStorage.setItem(
                  "userData",
                  JSON.stringify(res?.data?.data?.user[0])
                );
                window.location.reload(false);
              }
            } catch (error) {}

            setIsLoading(false);
          })
          .catch(() => {});
      })();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isSubmitted) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <h5 style={{ color: "#11a945" }}>Task Successfully Submitted!</h5>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        Authentication is in progress ... <Spinner />;
      </div>
    );
  }

  if (!isAuth) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <h3 style={{ color: "red" }}>Authentication Error</h3>

        <h5 style={{ color: "red" }}>Please Join From Regulator System!</h5>
        <div>
          &nbsp;
          <Button
            className="btn-icon"
            color="primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: 10 }}>
      <FormBuilder
        key={"tasks-ti"}
        isTeleInspection={true}
        onTaskSubmitSuccess={onTaskSubmitSuccess}
      />
    </div>
  );
};

const AddRoutes = [
  {
    element: <Users />,
    path: "/Users/Add",
    isAddRoute: true,
    default: true,
    route: "users",
    slug: "users",
    title: "users",
    isForm: true,
  },

  {
    element: <Modules />,
    path: "/Modules/Add",
    isAddRoute: true,
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },
  {
    element: <Modules key="editmodules" />,
    path: "/Modules/Edit/:id",
    permissions: ["admin"],
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },

  {
    element: <RiskCompliance />,
    path: "/Standards/Add",
    isAddRoute: true,
    route: "Risk & Compliance",
    slug: "Standards",
    title: "Risk & Compliance",
    isForm: true,
  },

  {
    element: <RiskCompliance key="editmodules" />,
    path: "/Standards/Edit/:id",

    isAddRoute: true,
    route: "Risk & Compliance",
    slug: "Standards",
    title: "Risk & Compliance",
    isForm: true,
  },

  {
    element: <Regions />,
    path: "/Regions/Add",
    isAddRoute: true,
    route: "Add Regions",
    slug: "Regions",
    title: "Add Regions",
    isForm: true,
  },

  {
    element: <Regions key="editregions" />,
    path: "/Regions/Edit/:id",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
    isForm: true,
  },
  {
    element: <Translation />,
    path: "/Translation",
    route: "Translation",
    slug: "Translation",
    title: "Translation",
    isForm: true,
  },
  {
    element: <Sites />,
    path: "Sites/Add",
    isAddRoute: true,
    route: "Sites",
    slug: "Sites",
    title: "Sites",
    isForm: true,
  },
  {
    element: <Sites key="editsites" />,
    path: "/Sites/Edit/:id",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
    isForm: true,
  },

  {
    element: <Clusters />,
    path: "Clusters/Add",
    isAddRoute: true,
    route: "Clusters",
    slug: "Clusters",
    title: "Clusters",
    isForm: true,
  },
  {
    element: <Clusters key="editClusters" />,
    path: "/Clusters/Edit/:id",
    route: "Clusters",
    slug: "Clusters",
    title: "Clusters",
    isForm: true,
  },
  {
    element: <Users key={"UsersEdit"} />,
    path: "Users/Edit/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
    isForm: true,
  },
  {
    element: <AdminMedicines key={"obligation-medicines"} />,
    path: "/Medicines/Add",
    isAddRoute: true,
    isForm: true,
    title: "Medicines",
    permissions: ["admin"],
  },
  {
    element: <SelfAssessment />,
    path: "/Self-Assessment/Add",
    isAddRoute: true,
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
    default: true,
    isForm: true,
  },
  {
    element: <SelfAssessment key={"edit-self-assessment"} />,
    path: "/Self-Assessment/Edit/:id",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
    default: true,
    isForm: true,
  },

  {
    element: <SelfAssessment key={"tasks-route"} />,
    path: "/Self-Assessment/Add/:task_id",
    route: "Self-Assessment",
    slug: "Self-Assessment",
    title: "Self Assessment - Medicine Availability Form",
    isForm: true,
  },
  // Schedule Task
  {
    element: <ScheduleTask />,
    path: "/Schedule-Task/Add",
    isAddRoute: true,
    route: "Schedule Task",
    slug: "Schedule Task",
    title: "Schedule Task Form",
    isForm: true,
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },

  {
    element: <ScheduleAvailability />,
    path: "/Schedule-Task-Availability/Add",
    isAddRoute: true,
    route: "Schedule Availability",
    slug: "Schedule Availability",
    title: "Schedule Availability Form",
    isForm: true,
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },

  {
    element: <ScheduleMedicalError />,
    path: "/Schedule-Medical-Error/Add",
    isAddRoute: true,
    route: "Schedule Medical Error",
    slug: "Schedule Medical Error",
    title: "Schedule Medical Error Form",
    isForm: true,
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    element: <ScheduleTask />,
    path: "/Schedule-Task/Edit/:id",
    route: "Schedule Task",
    slug: "Schedule Task",
    title: "Schedule Task Form",
    isForm: true,
  },
  {
    element: <ScheduleSelfAssessment />,
    path: "/Schedule-Self-Assessment/Add",
    isAddRoute: true,
    route: "Schedule-Self-Assessment",
    slug: "Schedule-Self-Assessment",
    title: "Schedule Self Assessment",
    default: true,
    isForm: true,
    onlyFor: [
      "admin",
      "regional representatives",
      "facility",
      "facility manager",
      "cluster manager"
    ],
  },
  {
    element: <ScheduleMedicineAvailability />,
    path: "/Schedule-Medicine-Availability/Add",
    isAddRoute: true,
    route: "Schedule-Medicine-Availability",
    slug: "Schedule-Medicine-Availability",
    title: "Schedule Medicine Availability",
    default: true,
    isForm: true,
    onlyFor: [
      "admin",
      "regional representatives",
    ],
  },
  {
    element: <ScheduleMedicineAvailability />,
    path: "/Schedule-Medicine-Availability/Edit/:id",
    isAddRoute: true,
    route: "Schedule-Medicine-Availability",
    slug: "Schedule-Medicine-Availability",
    title: "Schedule Medicine Availability",
    default: true,
    isForm: true,
    onlyFor: [
      "admin",
      "regional representatives",
      "supervisor"
    ],
  },
  {
    element: <MedicineAvailability key={"edit-mddicine"} />,
    path: "/Medicine-Availability/Edit/:id",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
    isForm: true,
  },

  {
    element: <MedicineAvailability key={"add-mddicine"} />,
    path: "/Medicine-Availability/Add",
    isAddRoute: true,
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
    isForm: true,
  },
  {
    element: <MedicineAvailability key={"tasks-route"} />,
    path: "/Medicine-Availability/Add/:task_id",
    route: "Medicine-Availability",
    slug: "Medicine-Availability",
    title: "Medicine Availability Form",
    isForm: true,
  },

  {
    element: <PreOuth key={"pre-auth"} />,
    path: "/TI/:form_id/Form-Builder/Add/:task_id",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false,
    },
    isAuth: true,
  },

  {
    element: <RoleManagement key="editmodules" />,
    path: "/RoleManagement",

    isAddRoute: true,
    route: "Risk & Compliance",
    slug: "Standards",
    title: "Risk & Compliance",
    isForm: true,
  },

  {
    element: <RoleList key="editmodules" />,
    path: "/rolelist",

    isAddRoute: true,
    route: "Risk & Compliance",
    slug: "Standards",
    title: "Risk & Compliance",
    isForm: true,
  },
];
export default AddRoutes;

export const defaultRoutes = {
  user: Users,
};
